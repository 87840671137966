import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { useFirestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { useSelector } from 'react-redux';
import SplashScreen from '../layout/SplashScreen';
import ButtonWithConfirmation from '../layout/ButtonWithConfirmation';
import { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import firebase from './../../config/fbConfig'
import { doubleToCurrency } from '../../utility/currencyHelper';

function PreferenceListDetailsScreen(props) {
  const supplierId = useSelector((state) => state.auth.supplierId);
  const [loading, setLoading] = useState(false);

  useFirestoreConnect([
    {
      collection: "suppliers",
      doc: supplierId,
      subcollections: [{
        collection: 'preferenceLists',
        doc: props.match.params.id,
      }],
      storeAs: 'preferenceLists_' + props.match.params.id,
    },
    {
      collection: "suppliers",
      doc: supplierId,
      subcollections: [{
        collection: 'products',
        where: [
          ["preferenceLists", "array-contains", props.match.params.id]
        ]
      }],
      storeAs: 'preferenceListProducts_' + props.match.params.id,
    },
  ]);

  const list = useSelector((state) => state.firestore.data['preferenceLists_' + props.match.params.id]);
  const products = useSelector((state) => state.firestore.ordered['preferenceListProducts_' + props.match.params.id]);

  async function onDelete() {
    try {
      setLoading(true);
      const firestore = firebase.firestore();

      await firestore.collection("suppliers").doc(supplierId).collection("preferenceLists").doc(props.match.params.id).delete();

      setLoading(false);
      props.history.push("/preferences");
    }
    catch (err) {
      toastr.error("Errore inaspettato.");
      setLoading(false);
    }
  }

  if (!isLoaded(list) || !isLoaded(products))
    return <SplashScreen />;

  if (isEmpty(list))
    return <div className="container my-4">Lista di preferenze inesistente.</div>

  return <div className="container my-4">
    <div className="d-flex justify-content-between align-items-center">
      <h4 className="my-auto">{list.name}</h4>
      <div className="my-auto d-flex">
        <Link className="btn btn-outline-secondary mr-2" to={{ pathname: "/preferences/edit", state: { list: list } }} disabled={loading}>Modifica</Link>
        <ButtonWithConfirmation className="btn btn-outline-danger" buttonText="Elimina"
          modalMessage="Sicuro di voler eliminare questa lista di preferenze?" onConfirm={onDelete} disabled={loading} />
      </div>
    </div>
    <div className="mt-2">
      <div className="mt-1">
        Titolo in-app: <b>{list.displayName ?? list.name}</b>
      </div>
      {list.hasMin &&
        <div className="mt-1">
          Minimo di elementi da selezionare: <b>{list.min}</b>
        </div>
      }
      {list.hasMax &&
        <div className="mt-1">
          Massimo di elementi selezionabili: <b>{list.max}</b>
        </div>
      }
    </div>
    <div className="my-3">
      {list.preferences.map((p) =>
        <div key={p.id} className="card card-body py-2 mt-1" style={{ maxWidth: "700px" }}>
          <div className="d-flex justify-content-between align-items-center">
            <div>{p.name}</div>
            <div>{p.price > 0 && <span>+</span>}{doubleToCurrency(p.price)}</div>
          </div>
        </div>
      )}
    </div>
    <div className="mt-4">
      <h5>Prodotti associati</h5>
      {isEmpty(products) && <div>Nessun prodotto attualmente associato a questa lista.</div>}
      {products.map((product) =>
        <div key={product.reference.id} className="my-1" style={{ maxWidth: "700px" }}>
          <Link to={"/products/" + product.reference.id} className="card-link" >
            <div className="card card-body py-2">
              <div className="justify-content-between d-flex">
                <div className="d-flex align-items-center">
                  <i className={"disabled-circle mr-2 fas fa-circle fa-xs " + (product.disabled ? "text-warning" : "text-success")} />
                  <div>{product.name}</div>
                </div>
                <div>{doubleToCurrency(product.price)}</div>
              </div>
            </div>
          </Link>
        </div>
      )}
    </div>
  </div>
}

export default withRouter(PreferenceListDetailsScreen);