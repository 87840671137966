import React, { Component } from 'react'
import NumberFormat from 'react-number-format';
import nextId from 'react-id-generator';
import CategorySelect from './CategorySelect';
import SingleImageUploader from '../../layout/SingleImageUploader';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import SplashScreen from '../../layout/SplashScreen';
import PreferenceListsOrder from './PreferenceListsOrder';
import { arrayMove } from 'react-sortable-hoc';

class ProductForm extends Component {
  constructor(props) {
    super(props);
    if (props.mode === 'create') {
      this.state = {
        name: "",
        description: "",
        imageFile: null,
        price: 1.0,
        maxQuantity: 1,
        hasMaxQuantity: false,
        category: props.defaultCategory ?? null,
        newCategory: false,
        imageRemoved: false,
        preferenceLists: [],
        searchPreferences: "",
      }
    }
    else if (props.mode === 'edit') {
      let product = this.props.product;
      this.state = {
        name: product?.name ?? "",
        description: product?.description ?? "",
        imageFile: null,
        price: product?.price ?? 1.0,
        maxQuantity: product?.maxQuantity && product?.maxQuantity !== 0 ? product?.maxQuantity : 1,
        hasMaxQuantity: product?.maxQuantity !== 0,
        category: product?.category ?? null,
        newCategory: false,
        imageRemoved: false,
        preferenceLists: product?.preferenceLists ?? [],
        searchPreferences: "",
      }
    }
  }

  checkboxId = nextId();

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  handleCategoryChange = (category, newCategory = false) => {
    this.setState({
      category: category,
      newCategory: newCategory,
    })
  }

  handlePriceChange = ({ floatValue }) => {
    this.setState({
      price: floatValue,
    })
  }

  handleHasMaxQuantityChange = (e) => {
    this.setState({
      hasMaxQuantity: e.target.checked,
    })
  }

  handleMaxQuantityChange = (e) => {
    this.setState({
      maxQuantity: parseInt(e.target.value),
    })
  }

  handleImageChange = (imageFile) => {
    this.setState({
      imageFile: imageFile,
      imageRemoved: imageFile === null,
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.onSave(this.state)
  }

  changePreferencesOrder = ({ oldIndex, newIndex }) => {
    let lists = this.state.preferenceLists.slice();
    lists = arrayMove(lists, oldIndex, newIndex);
    this.setState({ preferenceLists: lists });
  };

  render() {
    const { categories, mode, product, preferenceLists } = this.props;
    let defaultImage;
    if (mode === 'edit') {
      defaultImage = product.imageUrl;
    }

    if (!isLoaded(preferenceLists))
      return <SplashScreen />;

    let selectedPreferenceLists = [];
    for (let id of this.state.preferenceLists) {
      let list = preferenceLists.find((l) => l.id === id);
      if (list)
        selectedPreferenceLists.push(list);
    }

    let filteredPreferenceLists = preferenceLists.filter((l) => l.name.toLowerCase().includes(this.state.searchPreferences.toLowerCase().replace(/\s+/g, '')));
    return (
      <form onSubmit={this.handleSubmit} style={{ maxWidth: "800px" }}>
        <div className="form-group" style={{ maxWidth: "400px" }}>
          <label className="required">Nome</label>
          <input type="text" id="name" className="form-control mr-3" value={this.state.name} onChange={this.handleChange} required />
        </div>
        <CategorySelect categories={categories ?? []} onCategoryChange={this.handleCategoryChange} defaultCategory={this.state.category} />
        <div className="form-group" style={{ maxWidth: "200px" }}>
          <label className="required">Prezzo</label>
          <NumberFormat
            decimalScale={2}
            fixedDecimalScale={true}
            allowNegative={false}
            thousandSeparator={false}
            decimalSeparator=","
            prefix="€"
            className="form-control mr-3"
            value={this.state.price}
            onValueChange={this.handlePriceChange} required />
        </div>
        <div className="form-group">
          <label>Descrizione</label>
          <textarea className="form-control" id="description" onChange={this.handleChange} value={this.state.description} />
        </div>
        <SingleImageUploader required={false} title="Immagine" onImageChange={this.handleImageChange} defaultImage={defaultImage} edited={this.state.edited} />
        <div className="custom-control custom-checkbox mb-2">
          <input id={this.checkboxId} type="checkbox" className="custom-control-input" checked={this.state.hasMaxQuantity} onChange={this.handleHasMaxQuantityChange} />
          <label className="custom-control-label" htmlFor={this.checkboxId}>Questo prodotto ha una quantità massima ordinabile</label>
        </div>
        {
          this.state.hasMaxQuantity === true &&
          <div className="form-group">
            <label className="required">Quantità massima ordinabile:</label>
            <input type="number" style={{ maxWidth: "200px" }} className="form-control mr-3" value={this.state.maxQuantity.toString()} onChange={this.handleMaxQuantityChange} min="1" step="1" required />
          </div>
        }
        <div className="my-4">
          <div className="d-flex justify-content-between align-items-center">
            <h5>Liste di preferenze associate</h5>
            <div>
              <Link to="/preferences" target="_blank">Gestione liste di preferenze</Link>
            </div>
          </div>
          {isEmpty(preferenceLists) &&
            <div>
              Nessuna lista di preferenze ancora creata.
              <br />
              <Link to="/preferences/edit" target="_blank">
                Clicca qui per crearne una.
              </Link>
            </div>
          }
          {!isEmpty(preferenceLists) &&
            <>
              <div className="card card-body" style={{ maxHeight: "500px", overflow: "auto" }}>
                <div className="form-group form-inline mb-3">
                  <label htmlFor="searchPreferences">Cerca per nome</label>
                  <input type="text" id="searchPreferences" className="form-control mx-sm-3" value={this.state.searchPreferences} onChange={this.handleChange} />
                </div>
                {filteredPreferenceLists.map((l) =>
                  <div key={l.id} className="d-flex align-items-center mb-2">
                    <div className="custom-control custom-checkbox mb-2 my-auto">
                      <input id={"preference-" + l.id} type="checkbox" className="custom-control-input" checked={this.state.preferenceLists.includes(l.id)}
                        onChange={(e) => {
                          let newList = this.state.preferenceLists.slice();
                          let index = newList.findIndex((id) => id === l.id);
                          if (index !== -1)
                            newList.splice(index, 1);
                          if (e.target.checked === true) {
                            newList.push(l.id);
                          }
                          this.setState({
                            preferenceLists: newList,
                          })
                        }} />
                      <label className="custom-control-label" htmlFor={"preference-" + l.id}>{l.name}</label>
                    </div>
                    <Link to={"/preferences/" + l.id} target="_blank">
                      <i className="fas fa-info-circle highlight-on-hover my-auto ml-2"></i>
                    </Link>
                  </div>
                )}
                {filteredPreferenceLists.length === 0 &&
                  <div className="mb-3">Nessuna lista di preferenze in base ai criteri di ricerca.</div>
                }
                <Link to="/preferences/edit" target="_blank">
                  + Crea nuova lista
                </Link>
              </div>
            </>
          }
          {!isEmpty(selectedPreferenceLists) &&
            <div className="mt-3 mb-2">
              <h5>Ordine delle liste di preferenze</h5>
              <PreferenceListsOrder preferenceLists={selectedPreferenceLists} onSortEnd={this.changePreferencesOrder} />
            </div>
          }
        </div>
        <div className="d-flex justify-content-between mt-4">
          <button type="button" className="btn btn-danger" onClick={this.props.onCancel} disabled={this.props.isSaving}>Annulla</button>
          <button className="btn btn-success" disabled={this.props.isSaving}>Salva</button>
        </div>
      </form>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    supplierId: state.auth.supplierId,
    preferenceLists: state.firestore.ordered.preferenceLists,
  }
}

export default withRouter(compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: 'suppliers',
        doc: props.supplierId,
        subcollections: [{
          collection: 'preferenceLists',
        }],
        storeAs: 'preferenceLists',
      },
    ];
  }),
)(ProductForm))
