import React, { Component } from 'react'
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { compose } from 'redux'
import SplashScreen from '../layout/SplashScreen';
import OrderStatus from './OrderStatus'
import firebase from 'firebase/app'
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getSupplierAmountForOrder } from '../../models/order';
import { doubleToCurrency } from '../../utility/currencyHelper';
import { getDateString, getTimeString } from '../../utility/dateHelper';

class OrderListHistory extends Component {
  render() {
    let { orders } = this.props;

    if (!isLoaded(orders)) {
      return <SplashScreen />
    }

    let deliveredOrders = orders.filter((o) => o.state === "DELIVERED");

    let orderComponents = orders.map((order) => {
      return (
        <div className="card my-2" key={order.reference.id}>
          <div className="card-body row">
            <Link to={"/order/" + order.reference.id} className="col-md-4 col-sm-12">
              Ordine <b>#{order.reference.id.toUpperCase()}</b>
            </Link>
            <div className="col-md-4 col-sm-12"><OrderStatus order={order} /></div>
            <div className="col-md-4 col-sm-12">{getDateString(order.shiftStartTime)} alle {getTimeString(order.shiftStartTime)}</div>
          </div>
        </div>
      )
    });

    if (isEmpty(orders)) {
      orderComponents =
        <div className="orderList my-2">
          Nessun ordine effettuato nel periodo selezionato.
        </div>;
    }

    let totalAmountComponents = <div />;

    let amount = deliveredOrders.reduce((prev, curr) => prev + curr.cartAmount, 0.0);
    let supplierAmount = deliveredOrders.reduce((prev, curr) => prev + getSupplierAmountForOrder(curr).amountWithIVA, 0.0);
    totalAmountComponents = <div className="mb-4">
      <div>Ordini arrivati a destinazione*: <b>{deliveredOrders.length}</b></div>
      <div>Totale ordini*: <b>{doubleToCurrency(amount)}</b></div>
      <div>Ammontare guadagnato (IVA inclusa)*: <b>{doubleToCurrency(supplierAmount)}</b></div>
      <div className="mt-2 small">* I dati sono riferiti al periodo selezionato</div>
    </div>

    return (
      <div className="orderList my-2">
        {totalAmountComponents}
        {orderComponents}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    supplierId: state.auth.supplierId,
    orders: state.firestore.ordered['orders'],
  }
}

export default compose(
  connect(mapStateToProps, null),
  firestoreConnect((props) => {
    let lowDate = moment(props.startDate, "YYYY-MM-DD");
    let highDate = moment(props.endDate, "YYYY-MM-DD").add(1, 'days');

    let lowTimestamp = firebase.firestore.Timestamp.fromDate(lowDate.toDate());
    let highTimestamp = firebase.firestore.Timestamp.fromDate(highDate.toDate());
    return [
      {
        collection: 'orders',
        orderBy: [['preferredDeliveryTimestamp', 'asc']],
        where: [
          ['supplierId', '==', props.supplierId],
          ['preferredDeliveryTimestamp', '>=', lowTimestamp],
          ['preferredDeliveryTimestamp', '<', highTimestamp]
        ],
      },
    ];
  })
)(OrderListHistory)