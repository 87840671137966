import React, { Component } from 'react'
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { compose } from 'redux'
import SplashScreen from '../../layout/SplashScreen';
import { changeProductIndexes } from '../../../store/actions/productActions'
import { Link } from 'react-router-dom';
import './styles/ProductList.css'
import { sortableHandle, sortableElement, sortableContainer, arrayMove } from 'react-sortable-hoc';
import ButtonWithConfirmation from './../../layout/ButtonWithConfirmation';
import firebase from 'firebase/app'
import { toastr } from 'react-redux-toastr';
import { doubleToCurrency } from '../../../utility/currencyHelper';

function ProductView({ product, withSortHandle }) {
  return <div className={"card card-body py-2 pr-4" + (withSortHandle ? " pl-0" : "")} style={{ maxWidth: "800px" }}>
    <div className="d-flex">
      {withSortHandle && <div className="d-flex my-auto"><DragHandle /></div>}
      <Link to={"/products/" + product.reference.id} className="card-link justify-content-between d-flex flex-grow-1" >
        <div className="d-flex align-items-center">
          <i className={"disabled-circle mr-2 fas fa-circle fa-xs " + (product.disabled ? "text-warning" : "text-success")} />
          <div>{product.name}</div>
        </div>
        <div>{doubleToCurrency(product.price)}</div>
      </Link>
    </div>
  </div>;
}

const DragHandle = sortableHandle(() => <i className="fas fa-bars px-4" style={{ cursor: "row-resize", color: "grey" }}></i>);

const ProductItem = sortableElement(({ product }) => <div className="my-1"><ProductView product={product} withSortHandle={true} /></div>);

const SortableLists = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

class ProductsList extends Component {
  changeProductOrder = (products, oldIndex, newIndex) => {
    products = arrayMove(products, oldIndex, newIndex);

    this.props.changeProductIndexes(products);
  };

  changeProductsState = async (products, enabled) => {
    const firestore = firebase.firestore();

    try {
      let batch = firestore.batch();

      for (let p of products) {
        batch.update(p.reference, { disabled: !enabled });
      }

      await batch.commit();
      toastr.success('Stato dei prodotti cambiati con successo!');
    }
    catch (err) {
      toastr.error(`Errore inaspettato!`);
      console.log(err);
    }
  }

  render() {
    let { products, supplier, categoryId } = this.props;

    if (!isLoaded(products) || !isLoaded(supplier))
      return <SplashScreen />
    let categories = supplier.categories ?? [];

    let category;

    if (categoryId) {
      category = categories.find((c) => c.id === categoryId);

      if (category === null) {
        return <div className="container my-4">Categoria inesistente</div>
      }

      products = products.filter((p) => p.category === categoryId);
    }

    let sortedProducts = products.slice();

    sortedProducts.sort((p1, p2) => {
      if (p1.category === p2.category) {
        if (p1.indexInCategory !== undefined && p2.indexInCategory !== undefined && p1.indexInCategory !== p2.indexInCategory)
          return p1.indexInCategory < p2.indexInCategory ? -1 : 1;
        if (p1.indexInCategory === undefined && p2.indexInCategory !== undefined)
          return 1;
        if (p2.indexInCategory === undefined && p1.indexInCategory !== undefined)
          return -1;
      }
      return p1.name.localeCompare(p2.name);
    });

    let productComponents;

    if (isEmpty(sortedProducts))
      productComponents = <p className="my-3">Nessun prodotto ancora aggiunto {category && <span>nella categoria selezionata</span>}.</p>
    else if (categoryId) {
      productComponents = <SortableLists onSortEnd={({ oldIndex, newIndex }) => this.changeProductOrder(sortedProducts, oldIndex, newIndex)} useDragHandle lockAxis="y">
        {sortedProducts.map((product, index) => {
          return <ProductItem index={index} product={product} key={product.reference.id} />;
        })}
      </SortableLists>
    }
    else {
      productComponents = sortedProducts.map((product) =>
        <div key={product.reference.id} className="my-1">
          <ProductView product={product} withSortHandle={false} />
        </div >
      );
    }

    let hasDisabledProducts = products.reduce((val, p) => val || p.disabled, false);
    let hasEnabledProducts = products.reduce((val, p) => val || !p.disabled, false);

    return (
      <div className="productSummary container my-4">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h4 className="mb-0">Prodotti</h4>
            {category && <h6 className="mt-0" style={{ color: "#777777" }}>Categoria &quot;{category.name}&quot;</h6>}
          </div>
          <div className="d-flex">
            {
              category && hasDisabledProducts &&
              <ButtonWithConfirmation buttonText="Abilita tutti" className="btn btn-outline-success" modalMessage="Sicuro di voler riabilitare tutti i prodotti di questa categoria?"
                onConfirm={() => this.changeProductsState(products, true)} />
            }
            {
              category && hasEnabledProducts &&
              <ButtonWithConfirmation buttonText="Disabilita tutti" className="btn btn-outline-warning ml-2" modalMessage="Sicuro di voler disabilitare tutti i prodotti di questa categoria?"
                onConfirm={() => this.changeProductsState(products, false)} />
            }
            <Link className="btn btn-outline-primary my-auto ml-2" to={{ pathname: "/products/new", categoryId: category?.id }}>Aggiungi prodotto</Link>
          </div>
        </div>
        <div className="mt-2">
          {productComponents}
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    supplierId: state.auth.supplierId,
    supplier: state.firestore.data['supplier'],
    products: state.firestore.ordered['products'],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeProductIndexes: (products) => dispatch(changeProductIndexes(products)),
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: 'suppliers',
        doc: props.supplierId,
        storeAs: 'supplier'
      },
      {
        collection: 'suppliers',
        doc: props.supplierId,
        subcollections: [{
          collection: 'products',
        }],
        storeAs: 'products',
      },
    ];
  }),
  connect(null, mapDispatchToProps)
)(ProductsList)