import React, { Component } from 'react'
import SettingsForm from './SettingsForm';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded } from 'react-redux-firebase';
import SplashScreen from '../layout/SplashScreen';
import { updateSettings } from '../../store/actions/settingActions'
import { Link } from 'react-router-dom';

class Settings extends Component {

  handleFormSubmit = (data) => {
    this.props.updateSettings(data);
  }

  render() {
    let { supplier, supplierAdmin } = this.props;

    if (!isLoaded(supplier) || !isLoaded(supplierAdmin))
      return <SplashScreen />;
    return (
      <div className="settings container my-2">
        <div className="d-flex flex-wrap justify-content-between align-items-center">
          <h4 className="my-3">Impostazioni</h4>
          <Link to="/changePassword">Cambia password</Link>
        </div>
        <SettingsForm onSubmit={this.handleFormSubmit} supplierSettings={supplier} adminSettings={supplierAdmin} />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    updateSettings: (settings) => dispatch(updateSettings(props.supplierId, settings))
  }
}

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    supplierId: state.auth.supplierId,
    supplier: state.firestore.data['supplier'],
    supplierAdmin: state.firestore.data.supplierAdmin,
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: 'suppliers',
      doc: props.supplierId,
      storeAs: 'supplier'
    },
    {
      collection: 'supplier_admin',
      doc: props.uid,
      storeAs: 'supplierAdmin'
    }
  ]),
  connect(null, mapDispatchToProps))(Settings) 