import React from 'react'
import {
    Route,
    Redirect
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { provideSupplierId } from '../../store/actions/authActions';
import SplashScreen from '../layout/SplashScreen';
import { isEmpty } from 'react-redux-firebase';

export default function SupplierAdminRoute(props) {
    const { children, ...rest } = props;
    const dispatch = useDispatch();
    const { auth, profile, supplierId } = useSelector(state => ({
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        supplierId: state.auth.supplierId,
    }));

    return <Route {...rest}
        render={({ location }) => {
            // Il login non è stato eseguito
            if (isEmpty(profile) || isEmpty(auth))
                return (<Redirect
                    to={{
                        pathname: "/signin",
                        state: { from: location }
                    }}
                />);

            const isSupplierAdmin = profile.token.claims.supplierAdmin === true;

            // L'utente non è un fornitore
            if (!isSupplierAdmin)
                return (<Redirect
                    to={{
                        pathname: "/forbidden",
                        state: { from: location }
                    }}
                />);

            const isDisabled = profile.token.claims.disabled === true;

            // Il fornitore è stato disabilitato
            if (isDisabled)
                return (<Redirect
                    to={{
                        pathname: "/disabled",
                        state: { from: location }
                    }}
                />);

            // Non è ancora stato fornito allo store l'id del fornitore
            if (supplierId === null) {
                dispatch(provideSupplierId(profile.token.claims.supplierId));
                return <SplashScreen />;
            }

            return children;
        }} />


}