const initState = {
  authError: null,
  supplierId: null,
}

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case 'LOGIN_ERROR':
      console.log('login error');
      return {
        ...state,
        supplierId: null,
        authError: 'Accesso fallito'
      }

    case 'LOGIN_NOT_ADMIN':
      console.log('login not an admin');
      return {
        ...state,
        supplierId: null,
        authError: null,
      }

    case 'LOGIN_SUCCESS':
      console.log('login success');
      return {
        ...state,
        authError: null,
        supplierId: action.supplierId
      }

    case 'SUPPLIER_ID_PROVIDER':
      console.log('supplier id provided');
      return {
        ...state,
        supplierId: action.supplierId
      }

    case 'SIGNOUT_SUCCESS':
      console.log('signout success');
      return {
        ...state,
        authError: null,
        supplierId: null
      }

    default:
      return state
  }
};

export default authReducer;