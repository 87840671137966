import React, { Component } from 'react'
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { compose } from 'redux'
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import SplashScreen from '../../../layout/SplashScreen';
import CreateCategoryModal from '../../CreateCategoryModal';
import arrayMove from 'array-move';
import { updateCategories } from '../../../../store/actions/productActions'
import shortid from 'shortid';
import { Link } from 'react-router-dom'
import { capitalize } from '../../../../utility/stringHelper';

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      categoryName: props.category.name,
    }
  }

  render() {
    let { category, productCount } = this.props;

    let content;

    if (this.state.editing) {
      content = (
        <input className="input-underline" type="text" value={this.state.categoryName} onChange={(e) => this.setState({ categoryName: e.target.value })} />
      );
    }
    else
      content = (
        <div>
          <Link to={"/products/category/" + category.id}>
            {category.name}
          </Link>
          {
            productCount !== null &&
            (<div style={{ color: "#777777", fontSize: 12 }}>
              {productCount === 0 ? "Nessun prodotto" : productCount === 1 ? "1 prodotto" : (productCount + " prodotti")}
            </div>)
          }
        </div>
      );

    return <div className="card my-1 card-body d-flex py-2 pl-0 pr-4">
      <div className="d-flex">
        <div className="flex-grow-1 d-flex">
          <div className="d-flex my-auto"><DragHandle /></div>
          <div className="d-flex my-auto">
            {content}
          </div>
        </div>
        <div className="d-flex my-auto">
          {
            !this.state.editing &&
            <i className="fas fa-pen highlight-on-hover" onClick={() => { this.setState({ editing: true, categoryName: category.name }) }} />
          }
          {
            this.state.editing &&
            <i className="fas fa-check highlight-on-hover" onClick={() => { this.setState({ editing: false }); this.props.onNameChange(this.state.categoryName) }} />
          }
          {
            !this.state.editing && productCount === 0 &&
            <i style={{ fontSize: 18 }} className="fas fa-times highlight-on-hover ml-3" onClick={this.props.onDelete} />
          }
        </div>
      </div>
    </div>;
  }
}


const DragHandle = sortableHandle(() => <i className="fas fa-bars px-4" style={{ cursor: "row-resize", color: "grey" }}></i>);

const CategoryItem = sortableElement(({ category, onNameChange, productCount, onDelete }) =>
  <Category productCount={productCount} category={category} onNameChange={onNameChange} onDelete={onDelete} />
);

const SortableCategories = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

class ProductCategoriesSummary extends Component {
  state = {
    showingCreateModal: false,
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    let categories = this.props.supplier.categories;
    categories = arrayMove(categories, oldIndex, newIndex);
    this.props.updateCategories(categories);
  };

  onCategoryNameChange = (categoryId, newCategoryName) => {
    let categories = this.props.supplier.categories;
    let index = categories.findIndex((c) => c.id === categoryId);
    if (index === -1) return;

    categories[index].name = newCategoryName;
    this.props.updateCategories(categories);
  }

  onCategoryCreate = (categoryName) => {
    this.setState({ showingCreateModal: false });
    categoryName = categoryName.trim();
    categoryName = capitalize(categoryName);

    let category = {
      name: categoryName,
      id: shortid.generate(),
    }

    let categories = this.props.supplier.categories ?? [];

    this.props.updateCategories([...categories, category]);
  }

  onCategoryDelete = (categoryId) => {
    let categories = this.props.supplier.categories;
    let index = categories.findIndex((c) => c.id === categoryId);
    if (index === -1) return;

    categories.splice(index, 1);
    this.props.updateCategories(categories);
  }

  render() {
    let { supplier, products } = this.props;

    if (!isLoaded(supplier))
      return <SplashScreen />
    let categories = supplier.categories ?? [];
    return (
      <div className="container my-4">
        <div className="d-flex justify-content-between">
          <h4 className="my-auto">Categorie dei prodotti</h4>
          <button className="btn btn-outline-primary" onClick={() => this.setState({ showingCreateModal: true })}>Aggiungi categoria</button>
          <CreateCategoryModal onSave={this.onCategoryCreate} onCancel={() => this.setState({ showingCreateModal: false })} show={this.state.showingCreateModal} />
        </div>
        <div style={{ maxWidth: 500 }} className="mt-3">
          {categories.length === 0 &&
            "Nessuna categoria ancora aggiunta"
          }
          <SortableCategories onSortEnd={this.onSortEnd} useDragHandle lockAxis="y">
            {categories.map((category, index) => {
              let productCount = null;
              if (isLoaded(productCount)) {
                if (isEmpty(products))
                  productCount = 0;
                else
                  productCount = products.reduce((count, p) => p.category === category.id ? count + 1 : count, 0);
              }
              return <CategoryItem key={category.id} index={index} category={category} productCount={productCount} onDelete={() => this.onCategoryDelete(category.id)} onNameChange={(name) => this.onCategoryNameChange(category.id, name)} />
            })}
          </SortableCategories>
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    supplierId: state.auth.supplierId,
    supplier: state.firestore.data['supplier'],
    products: state.firestore.ordered['products'],
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    updateCategories: (categories) => dispatch(updateCategories(categories))
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: 'suppliers',
        doc: props.supplierId,
        storeAs: 'supplier'
      },
      {
        collection: 'suppliers',
        doc: props.supplierId,
        subcollections: [{
          collection: 'products',
        }],
        storeAs: 'products',
      },
    ];
  }),
  connect(null, mapDispatchToProps)
)(ProductCategoriesSummary)