import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';
import firebase from '../../../config/fbConfig'
import ProductForm from '../common/ProductForm'
import SplashScreen from '../../layout/SplashScreen';
import shortid from 'shortid';
import { resizeImage } from '../../../utility/fileHelper';
import { toastr } from 'react-redux-toastr';
import { capitalize } from '../../../utility/stringHelper';

class ProductCreateScreen extends Component {
  state = {
    isSaving: false,
  }


  onSave = async (product) => {
    this.setState({
      isSaving: true
    });

    const firestore = firebase.firestore();
    const storage = firebase.storage();

    let supplierId = this.props.supplierId;

    let supplier = (await firestore.collection('suppliers').doc(supplierId).get()).data();

    const documentRef = firestore.collection('suppliers').doc(supplierId).collection("products").doc();
    const storageRef = storage.ref().child('products/' + documentRef.id + "/full_image");

    try {
      let categoryId;
      if (product.newCategory) {
        categoryId = shortid.generate();
        let categories = supplier.categories ?? [];
        let categoryName = product.category.trim();
        categoryName = capitalize(categoryName);

        if (!categories.find(c => c.id === categoryId)) {
          categories.push({ 'id': categoryId, 'name': categoryName });
          await firestore.collection('suppliers').doc(supplierId).update({ 'categories': categories });
        }
      }
      else
        categoryId = product.category;



      let imageUrl = null;
      if (product.imageFile !== null && product.imageFile !== undefined) {
        let resizedImage = await resizeImage(product.imageFile, 600, 600, 90);
        await storageRef.put(resizedImage);
        imageUrl = await storageRef.getDownloadURL();
      }

      let productName = capitalize(product.name.trim());
      let productDescription = capitalize(product.description.trim());
      await documentRef.set({
        name: productName,
        description: productDescription,
        imageUrl: imageUrl,
        price: product.price,
        maxQuantity: product.hasMaxQuantity ? product.maxQuantity : 0,
        category: categoryId,
        disabled: false,
        reference: documentRef,
        supplierName: supplier.name,
        supplierId: supplierId,
        preferenceLists: product.preferenceLists,
      });

      var preferencePromises = [];
      for (let l of product.preferenceLists) {
        preferencePromises.push(firestore.collection('suppliers').doc(supplierId).collection("preferenceLists").doc(l).update({
          products: firebase.firestore.FieldValue.arrayUnion(documentRef.id),
        }));
      }

      await Promise.all(preferencePromises);

      toastr.success("Prodotto creato con successo!");
      this.props.history.goBack();
    }
    catch (err) {
      toastr.error(`Errore inaspettato!`);
      console.log(err);
      this.setState({
        isSaving: false
      });
    }
  }

  render() {
    let { isSaving } = this.state;
    let { supplier } = this.props;

    if (!isLoaded(supplier))
      return <SplashScreen />;

    let categories = supplier.categories ?? [];

    return <div className="container my-4">
      <h4>Crea un nuovo prodotto</h4>
      <ProductForm mode="create" defaultCategory={this.props.location.categoryId} categories={categories} onCancel={() => this.props.history.goBack()} onSave={this.onSave} isSaving={isSaving} />
    </div>
  }
}

const mapStateToProps = (state) => {
  return {
    supplierId: state.auth.supplierId,
    product: state.firestore.data.product,
    supplier: state.firestore.data.supplier,
  }
}

export default withRouter(compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: 'suppliers',
        doc: props.supplierId,
        storeAs: 'supplier'
      },
    ];
  }),
)(ProductCreateScreen))
