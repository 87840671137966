import React from 'react';
import AppStoreLogo from './app_store.png';
import PlayStoreLogo from './play_store.png';


function DashboardPlaceholder() {
  return (
    <div className="dashboard my-3 mx-5 d-flex justify-content-center align-items-center flex-column" >
      <div style={{ textAlign: "center", maxWidth: "500px" }}>
        Da questo aggiornamento della console dei fornitori, non sarà più possibile gestire ordini tramite la piattaforma web.
        <br />
        <br />
        Tutto ciò che riguarda la gestione degli ordini è stato spostato nell'apposita app scaricabile ai seguenti link:
      </div>
      <div className="d-flex justify-content-center mt-4">
        <a href="https://apps.apple.com/it/app/t-shop/id1541702520" target="_blank">
          <img src={AppStoreLogo} height="80px" style={{ cursor: "pointer" }} />
        </a>
        <div className="mx-2" />
        <a href="https://play.google.com/store/apps/details?id=it.taporty.supplier" target="_blank">
          <img src={PlayStoreLogo} height="80px" style={{ cursor: "pointer" }} />
        </a>
      </div>
    </div>
  )
}

export default DashboardPlaceholder;