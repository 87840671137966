
import { TimePicker } from '@material-ui/pickers';
import moment from 'moment';
import React, { FormEvent } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { WeekdayNumber, WeekdayTimetable } from "../../../models/supplier";
import { Link } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import firebase from 'firebase';

type WeekdayTimetableEditorProps = {
  initialWeekdayTimetable: WeekdayTimetable;
  shiftLength: number;
  supplierId: string;
}

function WeekdayTimetableEditor({ initialWeekdayTimetable, shiftLength, supplierId }: WeekdayTimetableEditorProps) {
  const [timetable, setTimetable] = useState(initialWeekdayTimetable);
  const [edited, setEdited] = useState(false);

  useEffect(() => {
    if (!edited)
      setTimetable(initialWeekdayTimetable);
  }, [initialWeekdayTimetable, edited]);

  const handleOpenChange = (isoIndex: WeekdayNumber, open: boolean) => {
    let newDayTimetable;
    if (open) {
      newDayTimetable = { ...timetable[isoIndex] };
      newDayTimetable.open = true;
      if (newDayTimetable.timeslots === undefined || newDayTimetable.timeslots === null) {
        newDayTimetable.timeslots = [
          {
            start: "00:00",
            end: "00:00"
          }
        ]
        newDayTimetable.timeslotCount = 1;
      }
    }
    else {
      newDayTimetable = { ...timetable[isoIndex] };
      newDayTimetable.open = false;
    }

    setTimetable({
      ...timetable,
      [isoIndex]: newDayTimetable,
    });
    setEdited(true);
  }

  const handleTimeslotCountChange = (isoIndex: WeekdayNumber, count: number) => {
    let newDayTimetable = { ...timetable[isoIndex] };
    let prevTimeslotCount = newDayTimetable.timeslotCount;
    let newTimeslotCount = newDayTimetable.timeslotCount = count;

    let diff = newTimeslotCount - prevTimeslotCount;

    if (diff > 0) {
      let start = moment("00:00", "HH:mm");

      if (prevTimeslotCount > 0)
        start = moment(newDayTimetable.timeslots[prevTimeslotCount - 1].end, "HH:mm");

      for (let i = 0; i < diff; i++) {
        newDayTimetable.timeslots.push({
          start: start.format("HH:mm"),
          end: start.add(shiftLength, "minutes").format("HH:mm"),
        });
      }
    }
    else if (diff < 0) {
      newDayTimetable.timeslots.splice(diff, -diff);
    }

    setTimetable({
      ...timetable,
      [isoIndex]: newDayTimetable,
    });

    setEdited(true);
  }

  const handleTimeslotChange = (isoIndex: WeekdayNumber, timeslotIndex: number, type: "start" | "end", value: string) => {
    let newDayTimetable = { ...timetable[isoIndex] };

    let valueDatetime = moment(value, "HH:mm");
    valueDatetime.set("minutes", valueDatetime.minutes() - valueDatetime.minutes() % shiftLength);

    newDayTimetable.timeslots[timeslotIndex][type] = valueDatetime.format("HH:mm");

    setTimetable({
      ...timetable,
      [isoIndex]: newDayTimetable,
    });
    setEdited(true);
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const firestore = firebase.firestore();

    const documentRef = firestore.collection('suppliers').doc(supplierId);

    try {
      await documentRef.set({
        weekdayTimetable: timetable,
      }, { merge: true });

      toastr.success("Orario settimanale aggiornato con successo!", "");
      setEdited(false);
    }
    catch (err) {
      toastr.error(`Errore inaspettato!`, "");
      console.log(err);
    }
  }

  let weekdays = moment.weekdays(true);
  let tableHeaders = weekdays.map((weekday) => {
    let weekdayCapitalized = weekday;
    weekdayCapitalized = weekdayCapitalized.charAt(0).toUpperCase() + weekdayCapitalized.slice(1);
    return <th key={weekday}>{weekdayCapitalized}</th>
  });

  let tableCells = weekdays.map((weekday) => {
    let isoIndex = moment(weekday, 'ddd').isoWeekday() as WeekdayNumber;
    let dayTimetable = timetable[isoIndex as WeekdayNumber];

    let timetableComponents;

    if (dayTimetable.open === true) {
      timetableComponents = dayTimetable.timeslots.map((timeslot, index) => {
        return (
          <div className="my-2" key={index}>
            <strong>Fascia {index + 1} </strong>
            <div className="my-1 row">
              <label className="col-4 my-auto">Inizio:</label>
              <div className="col-8 my-auto">
                <TimePicker value={moment(timeslot.start, "HH:mm")} onChange={(d) => handleTimeslotChange(isoIndex, index, "start", d!.format("HH:mm"))} minutesStep={shiftLength} ampm={false} required />
              </div>
            </div>
            <div className="my-1 row">
              <label className="col-4 my-auto">Fine:</label>
              <div className="col-8 my-auto">
                <TimePicker value={moment(timeslot.end, "HH:mm")} onChange={(d) => handleTimeslotChange(isoIndex, index, "end", d!.format("HH:mm"))} minutesStep={shiftLength} ampm={false} required />
              </div>
            </div>
          </div>
        )
      });
    }

    return (
      <td key={isoIndex} style={{ minWidth: "250px" }}>
        <div className="custom-control custom-radio custom-control-inline">
          <input type="radio" className="custom-control-input" name={weekday + "Open"} id={weekday + "Open"} checked={dayTimetable.open === true} onChange={(e) => handleOpenChange(isoIndex, e.target.checked)} />
          <label className="custom-control-label" htmlFor={weekday + "Open"}>Aperto</label>
        </div>
        <div className="custom-control custom-radio custom-control-inline">
          <input type="radio" name={weekday + "Open"} id={weekday + "Close"} className="custom-control-input" checked={dayTimetable.open === false} onChange={(e) => handleOpenChange(isoIndex, !e.target.checked)} />
          <label className="custom-control-label" htmlFor={weekday + "Close"}>Chiuso</label>
        </div>
        {
          dayTimetable.open === true &&
          <div className="mt-2">
            <div className="form-inline">
              <div className="form-group">
                <label className="mr-2">Fasce orarie:</label>
                <select style={{ maxWidth: "80px" }} value={dayTimetable.timeslotCount.toString()} className="form-control mr-3"
                  onChange={(e) => handleTimeslotCountChange(isoIndex, parseInt(e.target.value))} required>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
              </div>
            </div>
            {timetableComponents}
          </div>
        }
      </td>
    )
  });

  return (
    <div className="weekdayTimetable my-3">
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <h4 className="my-auto">Orario settimanale</h4>
        <Link to="/maxOrders" className="btn btn-primary">Configurazione numero massimo ordini</Link>
      </div>
      <form onSubmit={handleSubmit} >
        <div className="table-responsive my-2">
          <table className="table table-bordered">
            <thead>
              <tr>
                {tableHeaders}
              </tr>
            </thead>
            <tbody>
              <tr>
                {tableCells}
              </tr>
            </tbody>
          </table>
        </div>

        {edited &&
          <div className="my-2 d-flex justify-content-between">
            <button className="btn btn-danger" type="button" onClick={() => setEdited(false)}>Annulla</button>
            <button className="btn btn-success">Salva</button>
          </div>}
      </form>
    </div>
  )
}

export default WeekdayTimetableEditor;