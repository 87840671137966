import React, { Component } from 'react'

class SingleImageUploader extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFromProps(props);
  }

  stateFromProps(props) {
    let state;
    if (props.defaultImage) {
      state = {
        imagePreviewLink: props.defaultImage,
        imageRemoved: false,
        edited: props.edited,
      }
    }
    else {
      state = {
        imagePreviewLink: "",
        edited: props.edited,
      }
    }
    return state;
  }

  componentDidUpdate(prevProps) {
    if ((this.props.defaultImage !== prevProps.defaultImage) ||
      (this.props.edited === false && this.state.edited === true)) {
      this.setState(this.stateFromProps(this.props));
    }
  }

  handleImageSelect = (imageFile) => {
    if (imageFile !== undefined) {
      this.props.onImageChange(imageFile);

      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({
          imagePreviewLink: e.target.result,
          edited: true,
        });
      }
      reader.readAsDataURL(imageFile);
    }
  }

  handleImageDelete = () => {
    this.props.onImageChange(null);
    this.setState({
      imagePreviewLink: "",
      edited: true,
    });
  }

  render() {
    const { required } = this.props;
    return (
      <div className="form-group">
        <div className={required === true ? "required" : ""}>{this.props.title}</div>
        <div id="imagePreview" className={"imagePreview position-relative my-2 " + (this.state.imagePreviewLink && this.state.imagePreviewLink !== "" ? "d-block" : "d-none")}>
          <div className="deleteImage" onClick={this.handleImageDelete}>
            <i aria-hidden="true" className="fa fa-times" />
          </div>
          <img src={this.state.imagePreviewLink} style={{
            maxHeight: 300, maxWidth: "100%",
            boxShadow: "0 0 8px 2px rgba(0, 0, 0, 0.1)"
          }} />
        </div>
        <div className="custom-file" style={{ maxWidth: "400px" }}>
          <input type="file" className="custom-file-input" id="imageSelect" lang="it" required={required === true} onChange={(e) => this.handleImageSelect(e.target.files[0])} accept="image/png, image/jpeg" onClick={(event) => { event.target.value = null }} />
          <label className="custom-file-label" htmlFor="imageSelect">Scegli un&apos;immagine</label>
        </div>
      </div>
    );
  }

}

export default SingleImageUploader;