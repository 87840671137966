import React, { Component } from 'react'
import ProductsList from '../common/ProductsList';
import { withRouter } from 'react-router-dom';

class ProductCategoryScreen extends Component {
  render() {
    let categoryId = this.props.match.params.categoryId;
    return <ProductsList categoryId={categoryId} />
  }
}

export default withRouter(ProductCategoryScreen);