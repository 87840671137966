import { toastr } from "react-redux-toastr";
import { resizeImage } from '../../utility/fileHelper'

export const updateSettings = (supplierId, settings) => {
  return async (dispatch, getState, { getFirebase }) => {
    const uid = getState().firebase.auth.uid;
    const firebase = getFirebase();

    const firestore = firebase.firestore();
    const storage = firebase.storage();

    const documentRef = firestore.collection('suppliers').doc(supplierId);
    const adminDocumentRef = firestore.collection('supplier_admin').doc(uid);
    const storageRef = storage.ref().child('suppliers/' + supplierId);

    const iban = settings.iban.replace(/\s/g, '');
    if (!(/^([a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16})$/.test(iban))) {
      toastr.error("IBAN inserito non valido!");
      return;
    }
    try {
      let imageProps = {};
      if (settings.imageFile !== null) {
        let fullSizeImage = await resizeImage(settings.imageFile, 1200, 800, 100);
        let thumbnailImage = await resizeImage(settings.imageFile, 600, 400, 90);

        await storageRef.child("full_size_image").put(fullSizeImage);
        await storageRef.child("thumbnail_image").put(thumbnailImage);
        let fullSizeImageUrl = await storageRef.child("full_size_image").getDownloadURL();
        let thumbnailImageUrl = await storageRef.child("thumbnail_image").getDownloadURL();
        imageProps = {
          imageUrl: fullSizeImageUrl,
          thumbnailUrl: thumbnailImageUrl,
        }
      }

      await documentRef.set({
        name: settings.name,
        description: settings.description,
        iban: iban,
        ipa: settings.ipa,
        minOrderTime: parseInt(settings.minOrderTime),
        minOrderAmount: settings.minOrderAmount,
        useOwnDrivers: settings.useOwnDrivers,
        ...imageProps
      }, { merge: true });

      await adminDocumentRef.set({
        messageNotification: settings.messageNotification,
        whatsappNotification: settings.whatsappNotification,
        emailNotification: settings.emailNotification,
        phoneNumber: settings.adminPhoneNumber,
      }, { merge: true });

      toastr.success("Impostazioni aggiornate con successo!");
    }
    catch (err) {
      toastr.error(`Errore inaspettato!`);
      console.log(err);
    }
  }
};

export const changePassword = (data, history) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    const auth = firebase.auth();

    try {
      let user = auth.currentUser;
      let credentials = firebase.auth.EmailAuthProvider.credential(user.email, data.oldPassword);
      await user.reauthenticateWithCredential(credentials);
      await user.updatePassword(data.password);

      history.push('/settings');
      toastr.success("Password cambiata con successo!");
    }
    catch (err) {
      if (err.code === "auth/wrong-password")
        toastr.error('Password corrente inserita errata')
      else {
        toastr.error(`Errore inaspettato!`);
        console.log(err);
      }
    }
  };
};