import React from 'react'
import { signOut } from '../../store/actions/authActions'
import { NavLink, Link } from "react-router-dom";
import { Nav, Navbar, Button } from "react-bootstrap";
import { useDispatch } from 'react-redux';

const MyNavbar = () => {
  const dispatch = useDispatch();
  return (
    <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
      <Navbar.Brand as={Link} to="/" >
        Taporty
        </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav>
          <Nav.Link as={NavLink} exact to="/">Dashboard</Nav.Link>
        </Nav>
        <Nav>
          <Nav.Link as={NavLink} to="/orderHistory">Storico ordini</Nav.Link>
        </Nav>
        <Nav>
          <Nav.Link as={NavLink} to="/products">Prodotti</Nav.Link>
        </Nav>
        <Nav>
          <Nav.Link as={NavLink} to="/preferences">Preferenze</Nav.Link>
        </Nav>
        <Nav>
          <Nav.Link as={NavLink} to="/timetable">Orari e festività</Nav.Link>
        </Nav>
        <Nav>
          <Nav.Link as={NavLink} to="/settings">Impostazioni</Nav.Link>
        </Nav>
        <Nav className="ml-lg-auto">
          <div>
            <Button variant="outline-light" onClick={() => dispatch(signOut())}>Esci</Button>
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default MyNavbar
