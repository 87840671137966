import { TimePicker } from "@material-ui/pickers";
import firebase from "firebase";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import Holiday from "../../../models/holiday";

type HolidayEditorProps = {
  holidays: Holiday[];
  supplierId: string;
  shiftLength: number;
}

function HolidayEditor({ holidays, supplierId, shiftLength }: HolidayEditorProps) {
  const [showModal, setShowModal] = useState(false);

  const setHolidays = async (newHolidays: Holiday[]) => {
    const firestore = firebase.firestore();

    const documentRef = firestore.collection('suppliers').doc(supplierId);

    try {
      await documentRef.set({
        holidays: newHolidays,
      }, { merge: true });

      toastr.success("Ferie aggiornate con successo!", "");
    }
    catch (err) {
      toastr.error(`Errore inaspettato!`, "");
      console.log(err);
    }
  }

  let holidayComponents = holidays.map((holiday, index) => {
    return (
      <div key={holiday.name + holiday.start + holiday.end} className="my-3 col-xl-4 col-md-6 col-xs-12">
        <div className="card">
          <div className="card-body d-flex justify-content-between">
            <div>
              <strong>{holiday.name}</strong><br />
              <div>Dal {moment(holiday.start, "DD-MM").format("D MMMM")} alle {holiday.startTime ?? "00:00"} al {moment(holiday.end, "DD-MM").format("D MMMM")} alle {holiday.endTime ?? "00:00"} </div>
            </div>
            <div className="text-right">
              <button className="btn btn-outline-danger btn-sm" onClick={() => {
                let newHolidays = holidays.slice();
                newHolidays.splice(index, 1);
                setHolidays(newHolidays);
              }}><i className="fa fa-trash" /></button>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="holidaySummary my-4">
      <div className="d-flex justify-content-between">
        <h4 className="my-auto">Ferie</h4>
        <button className="btn btn-outline-primary" onClick={() => setShowModal(true)}>Aggiungi ferie</button>
      </div>
      <div className="row">
        {holidayComponents}
      </div>
      <CreateHolidayModal onCancel={() => setShowModal(false)} onSave={(holiday) => {
        let newHolidays = holidays.slice();
        newHolidays.push(holiday);
        setHolidays(newHolidays);
        setShowModal(false);
      }} show={showModal} shiftLength={shiftLength} />
    </div>
  )
}

export default HolidayEditor;

type CreateHolidayModalProps = {
  onCancel: () => void;
  show: boolean;
  onSave: (h: Holiday) => void;
  shiftLength: number;
}

function CreateHolidayModal({ onCancel, show, onSave, shiftLength }: CreateHolidayModalProps) {
  const [name, setName] = useState("");
  const [start, setStart] = useState("01-01");
  const [startTime, setStartTime] = useState("00:00");
  const [end, setEnd] = useState("02-01");
  const [endTime, setEndTime] = useState("00:00");

  useEffect(() => {
    setName("");
    setStart("01-01");
    setEnd("02-01");
    setStartTime("00:00");
    setEndTime("00:00");
  }, [show]);

  let dates = [];
  let currentDate = moment('01-01', 'DD-MM');
  do {
    dates.push(currentDate.format('DD-MM'));
    currentDate.add(1, 'day');
  }
  while (dates[0] !== currentDate.format('DD-MM'));

  let dateOptions = dates.map((date) => <option value={date} key={date}>{date}</option>)

  return (
    <Modal show={show} onHide={onCancel}>
      <form onSubmit={(e) => {
        e.preventDefault();
        onSave({ name, start, end, startTime, endTime });
      }}>
        <Modal.Header closeButton>
          <Modal.Title>Aggiungi ferie</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="required">Nome</label>
            <input type="text" id="name" className="form-control mr-3" value={name} onChange={(e) => setName(e.target.value)} required />
          </div>
          <div className="form-group">
            <label className="required">Inizio</label>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div className="mr-2">Data</div>
                <select className="form-control" id="start" value={start} required onChange={(e) => setStart(e.target.value)}>
                  {dateOptions}
                </select>
              </div>
              <div className="d-flex align-items-center">
                <div className="mr-2">Ora</div>
                <div>
                  <TimePicker value={moment(startTime, "HH:mm")} onChange={(d) => setStartTime(d!.format("HH:mm"))} minutesStep={shiftLength} ampm={false} required />
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className="required">Fine</label>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div className="mr-2">Data</div>
                <select className="form-control" id="end" value={end} required onChange={(e) => setEnd(e.target.value)}>
                  {dateOptions}
                </select>
              </div>
              <div className="d-flex align-items-center">
                <div className="mr-2">Ora</div>
                <div>
                  <TimePicker value={moment(endTime, "HH:mm")} onChange={(d) => setEndTime(d!.format("HH:mm"))} minutesStep={shiftLength} ampm={false} required />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" type="button" onClick={onCancel}>
            Annulla
          </Button>
          <Button variant="success" type="submit">
            Salva
          </Button>
        </Modal.Footer>
      </form>
    </Modal >
  )
}