import React, { Component } from 'react'
import NumberFormat from 'react-number-format';
import SingleImageUploader from '../layout/SingleImageUploader';

class SettingsForm extends Component {
  constructor(props) {
    super(props);

    this.state = this.stateFromProps(props);
  }

  stateFromProps(props) {
    return {
      name: props.supplierSettings.name,
      description: props.supplierSettings.description,
      iban: props.supplierSettings.iban,
      ipa: props.supplierSettings.ipa ?? "",
      imageFile: null,
      minOrderTime: props.supplierSettings.minOrderTime ?? props.supplierSettings.shiftLength * 2,
      emailNotification: props.adminSettings.emailNotification ?? false,
      messageNotification: props.adminSettings.messageNotification ?? false,
      whatsappNotification: props.adminSettings.whatsappNotification ?? false,
      adminPhoneNumber: props.adminSettings.phoneNumber,
      minOrderAmount: props.supplierSettings.minOrderAmount ?? 0,
      useOwnDrivers: props.supplierSettings.useOwnDrivers ?? false,
    }
  }

  handleCancel = () => {
    this.setState({
      ...this.stateFromProps(this.props),
      edited: false,
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const data = Object.assign({}, this.state);

    this.setState({ edited: false });
    this.props.onSubmit(data);
  }

  handleImageChange = (imageFile) => {
    this.setState({
      imageFile: imageFile,
      edited: true,
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
      edited: true,
    });
  }

  handleCheckboxChange = (e) => {
    this.setState({
      [e.target.id]: e.target.checked,
      edited: true,
    });
  }

  componentDidUpdate(oldProps, oldState) {
    if (oldState.edited !== true && oldProps !== this.props) {
      this.setState(this.stateFromProps(this.props));
    }
  }

  render() {
    const { supplierSettings } = this.props;
    let defaultImage = supplierSettings.imageUrl;

    let minOrderTimeOptions = [];
    for (let i = supplierSettings.shiftLength >= 15 ? 1 : 2; i <= 6; i++)
      minOrderTimeOptions.push(supplierSettings.shiftLength * i);

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="card mb-4">
          <div className="mx-4 my-3">
            <h5>Scheda dell&apos;attività nell&apos;app</h5>
            <div className="form-group">
              <label htmlFor="name">Nome del ristorante</label>
              <input className="form-control" type="text" id="name" value={this.state.name} required onChange={this.handleChange} />
            </div>
            <div className="form-group">
              <label htmlFor="description">Descrizione</label>
              <textarea className="form-control" id="description" onChange={this.handleChange} value={this.state.description} />
            </div>
            <SingleImageUploader required={false} edited={this.state.edited} title="Immagine" onImageChange={this.handleImageChange} defaultImage={defaultImage} />

            <br />
            <h5 className="mt-3">Notifiche</h5>
            <div className="custom-control custom-checkbox my-2">
              <input type="checkbox" className="custom-control-input" id="emailNotification" checked={this.state.emailNotification} onChange={this.handleCheckboxChange} />
              <label className="custom-control-label" htmlFor="emailNotification">Ricevi notifiche per email su {this.props.adminSettings.email}</label>
            </div>
            {
              /*
            <div className="form-group">
              <label htmlFor="adminPhoneNumber">Numero di cellulare</label>
              <input className="form-control" type="text" id="adminPhoneNumber" value={this.state.adminPhoneNumber} required onChange={this.handleChange} />
            </div> 
            <div className="custom-control custom-checkbox my-2">
              <input type="checkbox" className="custom-control-input" id="messageNotification" checked={this.state.messageNotification} onChange={this.handleCheckboxChange} />
              <label className="custom-control-label" htmlFor="messageNotification">Ricevi notifiche per messaggio su {this.state.adminPhoneNumber}</label>
            </div>
            <div className="custom-control custom-checkbox my-2">
              <input type="checkbox" className="custom-control-input" id="whatsappNotification" checked={this.state.whatsappNotification} onChange={this.handleCheckboxChange} />
              <label className="custom-control-label" htmlFor="whatsappNotification">Ricevi notifiche su Whatsapp su {this.state.adminPhoneNumber}</label>
            </div>

              */
            }

            <br />
            <h5 className="mt-3">Informazioni per i bonifici</h5>
            <div className="form-group">
              <label htmlFor="iban">IBAN</label>
              <input className="form-control" type="text" id="iban" value={this.state.iban} required onChange={this.handleChange} />
            </div>
            <div className="form-group">
              <label htmlFor="ipa">Codice univoco per la fatturazione elettronica (CUU o IPA)</label>
              <input className="form-control" type="text" id="ipa" value={this.state.ipa} required onChange={this.handleChange} />
            </div>

            <br />
            <h5 className="mt-3">Altre impostazioni</h5>
            <div className="form-group">
              <label>Tempo minimo per gli ordini</label>
              <select className="form-control" id="minOrderTime" value={this.state.minOrderTime} required onChange={this.handleChange}>
                {minOrderTimeOptions.map((o) => <option value={o} key={o}>{o} minuti</option>)}
              </select>
              <div className="mt-2" style={{ fontSize: "80%", color: "grey" }}>
                <i className="fas fa-info-circle mr-2"></i>
                Indica il tempo minimo selezionabile che deve passare fra l&apos;invio dell&apos;ordine da parte del cliente e la consegna di questo al fattorino.
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="minOrderAmount">Ammontare minimo ordini</label>
              <NumberFormat
                decimalScale={2}
                allowNegative={false}
                thousandSeparator={false}
                decimalSeparator=","
                prefix="€"
                fixedDecimalScale={true}
                className="form-control"
                value={this.state.minOrderAmount}
                onValueChange={({ floatValue }) => {
                  if (floatValue === this.state.minOrderAmount)
                    return;
                  this.setState({
                    edited: true,
                    minOrderAmount: floatValue,
                  })
                }} required />
              <div className="mt-2" style={{ fontSize: "80%", color: "grey" }}>
                <i className="fas fa-info-circle mr-2"></i>
                Indica il minimo ammontare che i clienti devono spendere per poter effettuare un ordine.
              </div>
            </div>
            {
              supplierSettings.canUseOwnDrivers &&
              <div className="custom-control custom-checkbox my-2">
                <input type="checkbox" className="custom-control-input" id="useOwnDrivers" checked={this.state.useOwnDrivers} onChange={this.handleCheckboxChange} />
                <label className="custom-control-label" htmlFor="useOwnDrivers">Utilizza i fattorini della tua attività per la consegna degli ordini</label>
              </div>
            }
          </div>
        </div>

        {(this.state.edited) &&
          <div className="my-2 d-flex justify-content-between">
            <button className="btn btn-danger" type="button" onClick={this.handleCancel}>Annulla</button>
            <button className="btn btn-success">Salva</button>
          </div>}

      </form>
    )
  }
}

export default SettingsForm;