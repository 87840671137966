import React, { } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import SignIn from './components/auth/SignIn'
import { useSelector } from 'react-redux'
import { isLoaded } from 'react-redux-firebase'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import SupplierAdminRoute from './components/auth/SupplierAdminRoute'
import SplashScreen from './components/layout/SplashScreen'
import Forbidden from './components/auth/Forbidden'
import MyNavbar from './components/layout/Navbar'
import OrderDetails from './components/orders/OrderDetails';
import OrderHistory from './components/orders/OrderHistory';
import ProductsScreen from './components/products/dashboard/ProductsScreen';
import ProductCategoryScreen from './components/products/category/ProductCategoryScreen';
import Settings from './components/settings/Settings';
import NonAdminRoute from './components/auth/NonAdminRoute';
import Disabled from './components/auth/Disabled';
import ChangePasswordScreen from './components/settings/ChangePasswordScreen';
import { Modal, Button } from 'react-bootstrap'
import ProductDetailsScreen from './components/products/details/ProductDetailsScreen';
import ProductEditScreen from './components/products/edit/ProductEditScreen';
import ProductCreateScreen from './components/products/create/ProductCreateScreen';
import MaxOrdersScreen from './components/max_orders/MaxOrdersScreen';
import PreferenceListScreen from './components/preferences/PreferenceListScreen';
import EditPreferenceListScreen from './components/preferences/EditPreferenceListScreen';
import PreferenceListDetailsScreen from './components/preferences/PreferenceListDetailsScreen';
import DashboardPlaceholder from './components/dashboard/DashboardPlaceholder';
import TimetableScreen from './components/timetable/TimetableScreen';
import { RootState } from './store/reducers/rootReducer';

const AuthIsLoaded: React.FunctionComponent<{}> = ({ children }) => {
  const { auth, profile } = useSelector((state: RootState) => ({ auth: state.firebase.auth, profile: state.firebase.profile }));
  if (!isLoaded(auth) || !isLoaded(profile))
    return <SplashScreen />;
  return <>{children}</>
}

function App() {
  const isServiceWorkerUpdated = useSelector(
    (state: RootState) => state.serviceWorker.serviceWorkerUpdated,
  );
  const serviceWorkerRegistration = useSelector(
    (state: RootState) => state.serviceWorker.serviceWorkerRegistration,
  );

  const updateServiceWorker = () => {
    const registrationWaiting = serviceWorkerRegistration.waiting;

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

      registrationWaiting.addEventListener('statechange', (e: any) => {
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  };

  return (
    <BrowserRouter>
      <AuthIsLoaded>
        <div className="App">
          <div className="App-alert">
            {isServiceWorkerUpdated && (
              <Modal show={true} onHide={updateServiceWorker}>
                <Modal.Body>
                  <div>Una nuova versione del sito è disponibile.</div>
                  <div>Clicca &apos;Ricarica&apos; per ricaricare la pagina.</div>
                </Modal.Body>
                <div className="d-flex justify-content-center mx-2 mb-2">
                  <Button variant="primary" type="submit" onClick={updateServiceWorker}>
                    Ricarica
                  </Button>
                </div>
              </Modal>
            )}
          </div>
          <Switch>
            <Route path='/signin' component={SignIn} />
            <NonAdminRoute path='/forbidden'>
              <Forbidden />
            </NonAdminRoute>
            <NonAdminRoute path='/disabled'>
              <Disabled />
            </NonAdminRoute>
            <SupplierAdminRoute exact path='/order/:orderId'>
              <MyNavbar />
              <OrderDetails />
            </SupplierAdminRoute>
            <SupplierAdminRoute exact path='/orderHistory'>
              <MyNavbar />
              <OrderHistory />
            </SupplierAdminRoute>
            <SupplierAdminRoute exact path='/products'>
              <MyNavbar />
              <ProductsScreen />
            </SupplierAdminRoute>
            <SupplierAdminRoute exact path='/products/new'>
              <MyNavbar />
              <ProductCreateScreen />
            </SupplierAdminRoute>
            <SupplierAdminRoute exact path='/products/:productId'>
              <MyNavbar />
              <ProductDetailsScreen />
            </SupplierAdminRoute>
            <SupplierAdminRoute exact path='/products/:productId/edit'>
              <MyNavbar />
              <ProductEditScreen />
            </SupplierAdminRoute>
            <SupplierAdminRoute exact path='/products/category/:categoryId'>
              <MyNavbar />
              <ProductCategoryScreen />
            </SupplierAdminRoute>
            <SupplierAdminRoute exact path='/preferences'>
              <MyNavbar />
              <PreferenceListScreen />
            </SupplierAdminRoute>
            <SupplierAdminRoute exact path='/preferences/edit'>
              <MyNavbar />
              <EditPreferenceListScreen />
            </SupplierAdminRoute>
            <SupplierAdminRoute exact path='/preferences/:id'>
              <MyNavbar />
              <PreferenceListDetailsScreen />
            </SupplierAdminRoute>
            <SupplierAdminRoute exact path='/timetable'>
              <MyNavbar />
              <TimetableScreen />
            </SupplierAdminRoute>
            <SupplierAdminRoute exact path='/maxOrders'>
              <MyNavbar />
              <MaxOrdersScreen />
            </SupplierAdminRoute>
            <SupplierAdminRoute exact path='/settings'>
              <MyNavbar />
              <Settings />
            </SupplierAdminRoute>
            <SupplierAdminRoute exact path='/changePassword'>
              <MyNavbar />
              <ChangePasswordScreen />
            </SupplierAdminRoute>
            <SupplierAdminRoute exact path='/'>
              <MyNavbar />
              <DashboardPlaceholder />
            </SupplierAdminRoute>
          </Switch>
        </div>
      </AuthIsLoaded>
    </BrowserRouter>
  );
}

export default App;
