import React from 'react'
import { getDateString, getTimeString } from '../../utility/dateHelper';

export default function OrderEventList(props) {
  if (props.events === null || props.events === undefined)
    return <div />

  if (props.events.length === 0)
    return <div />

  return (
    <div className="my-2">
      <h5>Storico eventi</h5>
      <table className="table table-bordered">
        <thead className="table-info">
          <tr>
            <th>Data</th>
            <th>Ora</th>
            <th>Descrizione</th>
          </tr>
        </thead>
        <tbody>
          {props.events.map((event) =>
            <tr key={event.timestamp.toMillis()}>
              <td style={{ whiteSpace: "nowrap" }}>{getDateString(event.timestamp)}</td>
              <td style={{ whiteSpace: "nowrap" }}>{getTimeString(event.timestamp)}</td>
              <td>
                {event.description}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}