import { toastr } from "react-redux-toastr";
import { resizeImage } from '../../utility/fileHelper'
import shortid from 'shortid';
import { capitalize } from "../../utility/stringHelper";

const createCategory = async (categoryId, categoryName, categories, supplierRef) => {
  categories = categories ?? [];
  categoryName = categoryName.trim();
  categoryName = capitalize(categoryName);

  if (!categories.find(c => c.id === categoryId)) {
    categories.push({ 'id': categoryId, 'name': categoryName });
    await supplierRef.update({ 'categories': categories });
  }
}

export const updateCategories = (categories) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const { auth: { supplierId } } = getState();

    let supplierRef = await firebase.firestore().collection('suppliers').doc(supplierId);

    try {
      await supplierRef.update({ 'categories': categories });
    }
    catch (err) {
      toastr.error(`Errore inaspettato!`);
      console.log(err);
    }
  }
};

export const createProduct = (supplierId, product) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    const firestore = firebase.firestore();
    const storage = firebase.storage();

    let supplier = await firebase.firestore().collection('suppliers').doc(supplierId).get();

    const documentRef = firestore.collection('suppliers').doc(supplierId).collection("products").doc();
    const storageRef = storage.ref().child('products/' + documentRef.id + "/full_image");

    try {
      let categoryId;
      if (product.newCategory) {
        categoryId = shortid.generate();
        await createCategory(categoryId, product.category, supplier.get("categories"), firestore.collection('suppliers').doc(supplierId));
      }
      else
        categoryId = product.category;

      let imageUrl = null;
      if (product.imageFile !== null && product.imageFile !== undefined) {
        let resizedImage = await resizeImage(product.imageFile, 600, 600, 90);
        await storageRef.put(resizedImage);
        imageUrl = await storageRef.getDownloadURL();
      }

      let productName = capitalize(product.name.trim());
      let productDescription = capitalize(product.description.trim());
      await documentRef.set({
        name: productName,
        description: productDescription,
        imageUrl: imageUrl,
        price: product.price,
        maxQuantity: product.hasMaxQuantity ? product.maxQuantity : 0,
        category: categoryId,
        disabled: false,
        reference: documentRef,
        supplierName: supplier.get('name'),
        supplierId: supplierId,
      });

      toastr.success("Prodotto creato con successo!");
    }
    catch (err) {
      toastr.error(`Errore inaspettato!`);
      console.log(err);
    }
  }
};

export const changeProductIndexes = (products) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    const firestore = firebase.firestore();

    try {
      let batch = firestore.batch();

      for (let i = 0; i < products.length; i++) {
        batch.update(products[i].reference, { indexInCategory: i });
      }

      await batch.commit();

    }
    catch (err) {
      toastr.error(`Errore inaspettato!`);
      console.log(err);
    }
  }
};

export const deleteProduct = (supplierId, productId) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    const firestore = firebase.firestore();

    const documentRef = firestore.collection('suppliers').doc(supplierId).collection("products").doc(productId);

    try {
      await documentRef.delete();
      toastr.success('Prodotto eliminato con successo!');
    }
    catch (err) {
      toastr.error(`Errore inaspettato!`);
      console.log(err);
    }
  }
};

export const changeProductState = (supplierId, productId, enabled) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    const firestore = firebase.firestore();

    const documentRef = firestore.collection('suppliers').doc(supplierId).collection("products").doc(productId);

    try {
      await documentRef.update({ disabled: !enabled });
      toastr.success('Stato del prodotto cambiato con successo!');
    }
    catch (err) {
      toastr.error(`Errore inaspettato!`);
      console.log(err);
    }
  }
};

export const editProduct = (supplierId, productId, product) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    const firestore = firebase.firestore();
    const storage = firebase.storage();

    let supplier = await firebase.firestore().collection('suppliers').doc(supplierId).get();

    const documentRef = firestore.collection('suppliers').doc(supplierId).collection("products").doc(productId);
    const storageRef = storage.ref().child('products/' + documentRef.id + "/full_image");

    try {
      let categoryId;
      if (product.newCategory) {
        categoryId = shortid.generate();
        await createCategory(categoryId, product.category, supplier.get("categories"), firestore.collection('suppliers').doc(supplierId));
      }
      else
        categoryId = product.category;

      let imageProps = {};
      if (product.imageFile !== null && product.imageFile !== undefined) {
        let resizedImage = await resizeImage(product.imageFile, 600, 600, 90);
        await storageRef.put(resizedImage);
        imageProps = { imageUrl: await storageRef.getDownloadURL() };
      }
      if (product.imageRemoved)
        imageProps = { imageUrl: null };


      let productName = capitalize(product.name.trim());
      let productDescription = capitalize(product.description.trim());

      await documentRef.update({
        name: productName,
        description: productDescription,
        price: product.price,
        maxQuantity: product.hasMaxQuantity ? product.maxQuantity : 0,
        category: categoryId,
        ...imageProps,
      });

      toastr.success("Prodotto modificato con successo!");
    }
    catch (err) {
      toastr.error(`Errore inaspettato!`);
      console.log(err);
    }
  }
};