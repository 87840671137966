import React, { Component } from 'react'

class CategorySelect extends Component {
  constructor(props) {
    super(props);
    this.state = this.stateFromProps(props);
  }

  stateFromProps(props) {
    let state;
    if (props.defaultCategory && props.categories.find(c => c.id === props.defaultCategory) !== undefined) {
      state = {
        categoryId: props.defaultCategory,
        newCategory: false,
        newCategoryValue: "",
      }
    }
    else if (props.categories.length === 0) {
      props.onCategoryChange("", true);
      state = {
        newCategory: true,
        categoryId: "new",
        newCategoryValue: "",
      }
    }
    else {
      props.onCategoryChange(props.categories[0].id);
      state = {
        categoryId: props.categories[0].id,
        newCategory: false,
        newCategoryValue: "",
      }
    }

    return state;
  }

  componentDidUpdate(prevProps) {
    let needsUpdate = prevProps.categories.length !== this.props.categories.length;
    if (!needsUpdate) {
      for (let i = 0; i < prevProps.categories.length; i++) {
        needsUpdate = prevProps.categories[i] !== this.props.categories[i];
        if (needsUpdate)
          break;
      }
    }

    if (!needsUpdate)
      needsUpdate = this.state.categoryId !== "new" && this.props.categories.find(c => c.id === this.state.categoryId) === undefined;

    if (needsUpdate) {
      this.setState(this.stateFromProps(this.props));
    }
  }

  handleCategoryChange = (e) => {
    if (e.target.value === "new") {
      this.props.onCategoryChange("", true);
      this.setState({
        newCategory: true,
        categoryId: "new",
        newCategoryValue: "",
      });
    }
    else {
      this.props.onCategoryChange(e.target.value);

      this.setState({
        categoryId: e.target.value,
        newCategory: false,
        newCategoryValue: "",
      });
    }
  }

  handleNewCategoryChange = (e) => {
    this.props.onCategoryChange(e.target.value, true);
    this.setState({
      newCategoryValue: e.target.value,
    });
  }

  render() {
    const { categories } = this.props;
    return (
      <div className="form-group" style={{ maxWidth: "400px" }}>
        <label className="required" htmlFor="category">Categoria</label>
        <select className="form-control" id="category" value={this.state.categoryId} required onChange={this.handleCategoryChange}>
          {categories && categories.map((category) => {
            return <option value={category.id} key={category.id}>{category.name}</option>
          })}
          <option value="new">Nuova categoria</option>
        </select>
        {this.state.newCategory === true &&
          <input className="form-control mt-2" type="text" placeholder="Nuova categoria" value={this.state.newCategoryValue} onChange={this.handleNewCategoryChange} required />}
      </div>
    );
  }

}

export default CategorySelect;