import firebase from 'firebase';
import moment, { Moment } from 'moment';

function getDateTimeString(timestamp: firebase.firestore.Timestamp): string {
  let datetime = moment(timestamp.toMillis());
  return datetime.format("D MMMM") + " alle " + datetime.format("HH:mm");
}

function getDateString(timestamp: firebase.firestore.Timestamp): string {
  let datetime = moment(timestamp.toMillis());
  return datetime.format("DD-MM-YYYY");
}

function getTimeString(timestamp: firebase.firestore.Timestamp): string {
  let datetime = moment(timestamp.toMillis());
  return datetime.format("HH:mm");
}

function getTimestamp(momentDatetime: Moment) {
  return firebase.firestore.Timestamp.fromMillis(momentDatetime.valueOf());
}

function getMomentDate(timestamp: firebase.firestore.Timestamp) {
  let datetime = moment(timestamp.toMillis());
  return datetime;
}

function getShortDateTimeString(timestamp: firebase.firestore.Timestamp) {
  let datetime = moment(timestamp.toMillis());
  return datetime.format("HH:mm") + " (" + datetime.format("DD-MM") + ")";
}

export { getDateString, getTimeString, getDateTimeString, getTimestamp, getMomentDate, getShortDateTimeString }