import React, { Component } from 'react'
import { isEmpty } from 'react-redux-firebase';
import { doubleToCurrency } from '../../utility/currencyHelper';

class Cart extends Component {
  render() {
    let { products, total } = this.props;

    if (isEmpty(products)) {
      return <div />
    }

    let productComponents = products.map((product, index) => {
      let price = product.price;
      let preferencesString = "";
      if (product.preferences) {
        for (let p of product.preferences) {
          price += p.price;
          preferencesString += p.name + ", ";
        }
        preferencesString = preferencesString.substring(0, preferencesString.length - 2);
      }
      price = price > 0 ? price : 0;
      if (preferencesString !== "")
        preferencesString += ". ";
      return (
        <div key={index} className="my-2 col-lg-6 col-md-12">
          <div className="d-flex justify-content-start">
            <div className="mr-2" style={{ maxWidth: "100px" }}>
              <img src={product.imageUrl} className="rounded" style={{ width: "100%" }}></img>
            </div>
            <div className="my-auto">
              <b>{product.name}</b>
              <div>
                <span className="mr-3">{doubleToCurrency(price)} </span>
                <b style={{ fontSize: "large" }}>x{product.quantity}</b>
              </div>
              {
                preferencesString !== "" &&
                <div className="mt-2" style={{ fontSize: 13 }}>{preferencesString}</div>
              }
              {
                product.notes && product.notes !== "" &&
                <div className="mt-2" style={{ fontSize: 13 }}><b>Nota</b>: {product.notes}</div>
              }
            </div>
          </div>
        </div>
      );
    });

    let title = this.props.title ?? "Carrello";

    return (
      <div>
        <h5>{title}</h5>
        <h6>
          Totale: {doubleToCurrency(total)}
        </h6>
        <div className="row">
          {productComponents}
        </div>
      </div>
    )
  }
}

export default Cart;