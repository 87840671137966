import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { withRouter, Link } from 'react-router-dom';
import SplashScreen from '../../layout/SplashScreen';
import DeleteModal from '../../layout/DeleteModal';
import placeholder from './files/placeholder.jpg'
import firebase from '../../../config/fbConfig'
import { toastr } from 'react-redux-toastr';
import { doubleToCurrency } from '../../../utility/currencyHelper';

class ProductDetailsScreen extends Component {
  state = {
    showingDeleteModal: false,
    isLoading: false,
  }

  handleDeleteButtonClick = () => {
    this.setState({
      showingDeleteModal: true,
    });
  }

  handleDeleteCancel = () => {
    this.setState({
      showingDeleteModal: false,
    })
  }

  handleDelete = async () => {
    this.setState({
      showingDeleteModal: false,
      isLoading: true,
    });
    const firestore = firebase.firestore();

    const documentRef = firestore.collection('suppliers').doc(this.props.supplierId).collection("products").doc(this.props.match.params.productId);

    try {
      await documentRef.delete();
      toastr.success('Prodotto eliminato con successo!');
      this.props.history.goBack();
    }
    catch (err) {
      toastr.error(`Errore inaspettato!`);
      console.log(err);
      this.setState({
        isLoading: false
      })
    }
  }

  changeProductState = async (enabled) => {
    this.setState({
      isLoading: true,
    })

    const firestore = firebase.firestore();

    const documentRef = firestore.collection('suppliers').doc(this.props.supplierId).collection("products").doc(this.props.match.params.productId);

    try {
      await documentRef.update({ disabled: !enabled });
      toastr.success('Stato del prodotto cambiato con successo!');
    }
    catch (err) {
      toastr.error(`Errore inaspettato!`);
      console.log(err);
    }
    finally {
      this.setState({
        isLoading: false
      })
    }
  }

  render() {
    const { product, supplier, preferenceLists } = this.props;
    const { isLoading } = this.state;
    if (!isLoaded(product) || !isLoaded(supplier) || !isLoaded(preferenceLists))
      return <SplashScreen />;

    if (isEmpty(product))
      return <div className="container my-4">Prodotto inesistente.</div>;

    let categories = supplier.categories ?? [];

    let category = categories.find((c) => c.id === product.category);

    let stateComponent;
    switch (product.disabled) {
      case false:
        stateComponent = <div>
          <i className="fas fa-circle text-success fa-xs" /> Il prodotto è abilitato.
        </div>
        break;
      case true:
        stateComponent = <div>
          <i className="fas fa-circle text-warning fa-xs" /> Il prodotto è disabilitato.
        </div>
        break;
    }

    let sortedPreferenceLists = preferenceLists ? preferenceLists.slice() : [];
    sortedPreferenceLists.sort((l1, l2) => {
      let l1Index = product.preferenceLists.findIndex((id) => l1.id === id);
      let l2Index = product.preferenceLists.findIndex((id) => l2.id === id);
      return l1Index > l2Index ? 1 : -1;
    })

    return (
      <div className="container my-4">
        <h4 className="mb-0">{product.name}</h4>
        <h6 className="mt-0" style={{ color: "#777777" }}>{category?.name ?? ""}</h6>
        <div className="card card-body" style={{ maxWidth: "800px" }}>
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="my-0">Scheda prodotto</h5>
            <div>
              <Link className="btn btn-outline-secondary" to={"/products/" + this.props.match.params.productId + "/edit"} disabled={isLoading}>Modifica</Link>
            </div>
          </div>
          <div className="d-flex justify-content-start align-items-center flex-wrap">
            <div className="mr-2">
              <img src={product.imageUrl !== null && product.imageUrl !== undefined && product.imageUrl !== "" ? product.imageUrl : placeholder}
                className="rounded mr-2 d-block" style={{ maxWidth: "200px", maxHeight: "200px" }}></img>
            </div>
            <div className="mt-2">
              <div><strong>Nome:</strong> {product.name}</div>
              <div><strong>Categoria:</strong> {category?.name ?? "Categoria inesistente"}</div>
              {
                product.description !== null && product.description !== undefined && product.description !== "" &&
                <div><strong>Descrizione:</strong> {product.description}</div>
              }
              <div>
                <strong>Prezzo:</strong> {doubleToCurrency(product.price)}
              </div>
              <div><strong>Quantità massima ordinabile:</strong> {product.maxQuantity === 0 ? "Illimitata" : product.maxQuantity}</div>
            </div>
          </div>
        </div>
        <div className="card card-body mt-3" style={{ maxWidth: "800px" }}>
          <div className="d-flex justify-content-between">
            <div>
              <h5>Status</h5>
              {stateComponent}
            </div>
            <div className="mt-2">
              {
                product.disabled === false &&
                <button className="btn btn-outline-warning" onClick={() => this.changeProductState(false)} disabled={isLoading}>Disabilita</button>
              }
              {
                product.disabled === true &&
                <button className="btn btn-outline-success" onClick={() => this.changeProductState(true)} disabled={isLoading}>Riabilita</button>
              }
            </div>
          </div>
        </div>
        <div className="my-4">
          <h5>Liste di preferenze associate</h5>
          {isEmpty(sortedPreferenceLists) && <div>Nessuna lista ancora associata a questo prodotto.</div>}
          {sortedPreferenceLists.map((l) =>
            <div key={l.id} className="my-1" style={{ maxWidth: "800px" }}>
              <Link to={"/preferences/" + l.id} className="card-link">
                <div className="card card-body py-2 mt-2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>{l.name}</div>
                    <div style={{ color: "grey" }}>{l.preferences.length === 0 ? "Nessun elemento" : (l.preferences.length === 1 ? "1 elemento" : (l.preferences.length.toString() + " preferenze"))}</div>
                  </div>
                </div>
              </Link>
            </div>
          )}
        </div>
        <div className="my-3">
          <button className="link-button" style={{ color: "red" }} onClick={this.handleDeleteButtonClick} disabled={isLoading}>Elimina il prodotto</button>
        </div>
        <DeleteModal title="Elimina prodotto" message="Sicuro di voler eliminare questo prodotto?" show={this.state.showingDeleteModal} onCancel={this.handleDeleteCancel} onDelete={this.handleDelete} />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    supplierId: state.auth.supplierId,
    product: state.firestore.data.product,
    supplier: state.firestore.data.supplier,
    preferenceLists: state.firestore.ordered['productPreferenceLists_' + props.match.params.productId]
  }
}

export default withRouter(compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: 'suppliers',
        doc: props.supplierId,
        storeAs: 'supplier'
      },
      {
        collection: 'suppliers',
        doc: props.supplierId,
        subcollections: [{
          collection: 'products',
          doc: props.match.params.productId
        }],
        storeAs: 'product',
      },
      {
        collection: "suppliers",
        doc: props.supplierId,
        subcollections: [{
          collection: 'preferenceLists',
          where: [["products", "array-contains", props.match.params.productId]]
        }],
        storeAs: 'productPreferenceLists_' + props.match.params.productId,
      },
    ];
  }),
)(ProductDetailsScreen))
