import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';
import firebase from '../../../config/fbConfig'
import ProductForm from '../common/ProductForm'
import SplashScreen from '../../layout/SplashScreen';
import shortid from 'shortid';
import { resizeImage } from '../../../utility/fileHelper';
import { toastr } from 'react-redux-toastr';
import { capitalize } from '../../../utility/stringHelper';

class ProductEditScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      product: null,
      isSaving: false,
    }

    this.loadProduct();
  }

  loadProduct = async () => {
    const firestore = firebase.firestore();

    let snap = await firestore.collection("suppliers").doc(this.props.supplierId).collection("products").doc(this.props.match.params.productId).get();
    this.setState({
      product: snap.exists ? snap.data() : null,
      loaded: true,
    })
  }

  onSave = async (product) => {
    this.setState({
      isSaving: true
    });

    const firestore = firebase.firestore();
    const storage = firebase.storage();

    let supplierId = this.props.supplierId;
    let productId = this.props.match.params.productId;

    let supplier = this.props.supplier;

    const documentRef = firestore.collection('suppliers').doc(supplierId).collection("products").doc(productId);
    const storageRef = storage.ref().child('products/' + documentRef.id + "/full_image");

    const oldLists = this.state.product.preferenceLists ?? [];

    try {
      let categoryId;
      if (product.newCategory) {
        categoryId = shortid.generate();
        let categories = supplier.categories ?? [];
        let categoryName = product.category.trim();
        categoryName = capitalize(categoryName);

        if (!categories.find(c => c.id === categoryId)) {
          categories.push({ 'id': categoryId, 'name': categoryName });
          await firestore.collection('suppliers').doc(supplierId).update({ 'categories': categories });
        }
      }
      else
        categoryId = product.category;

      let imageProps = {};
      if (product.imageFile !== null && product.imageFile !== undefined) {
        let resizedImage = await resizeImage(product.imageFile, 600, 600, 90);
        await storageRef.put(resizedImage);
        imageProps = { imageUrl: await storageRef.getDownloadURL() };
      }
      if (product.imageRemoved)
        imageProps = { imageUrl: null };


      let productName = capitalize(product.name.trim());
      let productDescription = capitalize(product.description.trim());

      await documentRef.update({
        name: productName,
        description: productDescription,
        price: product.price,
        maxQuantity: product.hasMaxQuantity ? product.maxQuantity : 0,
        category: categoryId,
        preferenceLists: product.preferenceLists,
        ...imageProps,
      });

      var preferencePromises = [];
      for (let l of oldLists) {
        if (!product.preferenceLists.includes(l)) {
          preferencePromises.push(firestore.collection('suppliers').doc(supplierId).collection("preferenceLists").doc(l).update({
            products: firebase.firestore.FieldValue.arrayRemove(documentRef.id),
          }));
        }
      }

      for (let l of product.preferenceLists) {
        if (!oldLists.includes(l)) {
          preferencePromises.push(firestore.collection('suppliers').doc(supplierId).collection("preferenceLists").doc(l).update({
            products: firebase.firestore.FieldValue.arrayUnion(documentRef.id),
          }));
        }
      }

      await Promise.all(preferencePromises);

      toastr.success("Prodotto modificato con successo!");
      this.props.history.goBack();
    }
    catch (err) {
      toastr.error(`Errore inaspettato!`);
      console.log(err);
      this.setState({
        isSaving: false
      });
    }
  }

  render() {
    let { product, loaded, isSaving } = this.state;
    let { supplier } = this.props;
    if (!loaded || !isLoaded(supplier))
      return <SplashScreen />;
    let categories = supplier.categories ?? [];
    return <div className="container my-4">
      <h4>Modifica prodotto - {product.name}</h4>
      <ProductForm mode="edit" product={product} categories={categories} onCancel={() => this.props.history.goBack()} onSave={this.onSave} isSaving={isSaving} />
    </div>
  }
}

const mapStateToProps = (state) => {
  return {
    supplierId: state.auth.supplierId,
    product: state.firestore.data.product,
    supplier: state.firestore.data.supplier,
  }
}

export default withRouter(compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: 'suppliers',
        doc: props.supplierId,
        storeAs: 'supplier'
      },
    ];
  }),
)(ProductEditScreen))
