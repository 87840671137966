import React from 'react';
import ProductCategoriesSummary from './components/ProductCategoriesSummary';
import ProductsList from '../common/ProductsList';

export default function ProductsScreen() {
  return (
    <div>
      <ProductCategoriesSummary />
      <ProductsList />
    </div>
  )
}