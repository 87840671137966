import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import WeekdayMaxOrderCount, { MaxOrderCount } from '../../models/weekday_max_order_count';
import Supplier, { WeekdayNumber } from '../../models/supplier';
import { RootState } from "../../store/reducers/rootReducer";
import SplashScreen from '../layout/SplashScreen';
import firebase from '../../config/fbConfig';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import Collapsible from 'react-collapsible';
import NumberFormat from 'react-number-format';
import Timetable from '../../models/timetable';
import './styles/MaxOrdersScreen.css';

function MaxOrdersScreen() {
  const supplierId = useSelector((state: RootState) => state.auth.supplierId)!;
  const [loaded, setLoaded] = useState(false);
  const [maxOrderCount, setMaxOrderCount] = useState<MaxOrderCount[] | null>(null);
  const [supplier, setSupplier] = useState<Supplier | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    loadMaxOrders();
  }, []);

  let loadMaxOrders = async () => {
    const firestore = firebase.firestore();
    let maxOrderCount: MaxOrderCount[] = [];

    let snap = await firestore.collection("suppliers").doc(supplierId).get();
    let supplier = snap.data() as Supplier;

    // Mappa sostitutiva in caso non sia definito il numero massimo ordini per un certo giorno della settimana
    let map: MaxOrderCount = {};
    let start = moment("00:00", "HH:mm");
    let end = moment("00:00", "HH:mm").add(24, "hours");

    for (let curr = start.clone(); curr.isBefore(end); curr.add(supplier.shiftLength, "minutes")) {
      map = {
        ...map,
        [curr.format("HH:mm")]: -1,
      };
    }

    let querySnap = await firestore.collection("suppliers").doc(supplierId).collection('maxOrderCount').get();
    let docs = querySnap.docs;

    for (let i = 1; i <= 7; i++) {
      let results = docs.filter((d) => d.id === i.toString());
      if (results.length === 0) {
        maxOrderCount[i] = map;
      }
      else {
        let maxOrders = (results[0].data() as WeekdayMaxOrderCount).maxOrders;
        maxOrderCount[i] = maxOrders;
      }
    }

    setSupplier(supplier);
    setMaxOrderCount(maxOrderCount);
    setLoaded(true);
  }

  let onSave = async () => {
    const firestore = firebase.firestore();
    setIsSaving(true);
    try {
      for (let i = 1; i <= 7; i++) {
        await firestore.collection("suppliers").doc(supplierId).collection('maxOrderCount').doc(i.toString()).set({ maxOrders: maxOrderCount![i], weekday: i });
      }

      toastr.success("Impostazioni salvate con successo!", "");
    }
    catch (err) {
      console.log(err);
      toastr.error("Errore inaspettato!", "");
    }
    finally {
      setIsSaving(false);
    }
  }

  if (!loaded)
    return <SplashScreen />;
  if (supplier === null)
    return <div className="container my-4">Area inesistente</div>

  let weekdayComponents = [];

  for (let i = 1; i <= 7; i++) {
    weekdayComponents.push(<WeekdayConfig
      shiftLength={supplier.shiftLength ?? 15}
      weekdayNumber={i as WeekdayNumber} key={i}
      maxOrderCount={maxOrderCount!}
      timetable={supplier.weekdayTimetable[i as WeekdayNumber]}
      onChange={(weekdayNumber, timeString, maxOrders) => {
        let newMaxOrderCount: MaxOrderCount[] = [...maxOrderCount!];

        newMaxOrderCount[weekdayNumber] = {
          ...maxOrderCount![weekdayNumber],
          [timeString]: maxOrders
        };

        setMaxOrderCount(newMaxOrderCount);
      }} />);
  }
  return <div className="container my-4">
    <div>
      <h4 className="mb-3">Configurazione numero massimo ordini</h4>
      {weekdayComponents}
      <div className="justify-content-end d-flex mt-2">
        <button className="btn btn-success" onClick={onSave} disabled={isSaving}>Salva</button>
      </div>
    </div>
  </div>
}

type WeekdayConfigProps = {
  shiftLength: number;
  weekdayNumber: WeekdayNumber;
  maxOrderCount: MaxOrderCount[];
  timetable: Timetable;
  onChange: (weekdayNumber: WeekdayNumber, time: string, maxOrders: number) => void
}

function WeekdayConfig({ maxOrderCount, onChange, shiftLength, timetable, weekdayNumber }: WeekdayConfigProps) {
  let weekdayName = moment.weekdays(true)[weekdayNumber - 1];
  weekdayName = weekdayName.charAt(0).toUpperCase() + weekdayName.slice(1);

  let toggleUnlimited = (weekdayNumber: WeekdayNumber, time: string, unlimited: boolean) => {
    let maxOrders = unlimited ? -1 : 0;
    onChange(weekdayNumber, time, maxOrders);
  }

  let splitComponents: JSX.Element[] = [];

  if (timetable.open === true) {
    for (let i = 0; i < timetable.timeslotCount; i++) {
      let start = moment(timetable.timeslots[i].start, "HH:mm");
      let end = moment(timetable.timeslots[i].end, "HH:mm");
      if (start.isSameOrAfter(end))
        end = end.add(1, "days");

      let startTimeComponents = [];
      let maxOrdersComponents = [];
      let unlimitedComponents = [];
      for (let curr = moment(start); curr.isBefore(end); curr.add(shiftLength, "minutes")) {
        let timeString = curr.format("HH:mm");
        startTimeComponents.push(<td key={"time" + timeString}>{timeString}</td>);

        let actualWeekdayNumber = (curr.weekday() !== start.weekday() ? (weekdayNumber % 7) + 1 : weekdayNumber) as WeekdayNumber;

        let weekdayMaxOrderCount = maxOrderCount[actualWeekdayNumber];
        let unlimited = weekdayMaxOrderCount[timeString] === -1;
        maxOrdersComponents.push(<td key={"maxOrders" + timeString} className={unlimited ? "disabled" : ""} onClick={unlimited ? () => toggleUnlimited(actualWeekdayNumber, timeString, !unlimited) : () => { }}>
          {unlimited ? "" :
            <NumberFormat
              decimalScale={0}
              defaultValue={0}
              allowNegative={false}
              thousandSeparator={false}
              className="input-number"
              value={weekdayMaxOrderCount[timeString]}
              size={4}
              onValueChange={({ floatValue }) => {
                if (floatValue !== undefined) {
                  onChange(actualWeekdayNumber, timeString, floatValue);
                }
              }} required />
          }
        </td>)
        unlimitedComponents.push(
          <td key={"unlimited" + timeString} className={"unlimited" + (unlimited ? "" : " unchecked")} onClick={() => toggleUnlimited(actualWeekdayNumber, timeString, !unlimited)}>
            {unlimited ? <i className="fas fa-check"></i> : ""}
          </td>
        )
      }
      let component = (
        <div className="mt-4 split" key={"split" + i}>
          <div>Turni dalle {start.format("HH:mm")} alle {end.format("HH:mm")}</div>
          <div className="table-container">
            <table className="table table-bordered mt-2 mb-1">
              <tbody>
                <tr>
                  <th scope="row" style={{ whiteSpace: "nowrap" }} className="fixed-side">Inizio turno</th>
                  {startTimeComponents}
                </tr>
                <tr>
                  <th scope="row" style={{ whiteSpace: "nowrap" }} className="fixed-side">N° massimo ordini</th>
                  {maxOrdersComponents}
                </tr>
                <tr>
                  <th scope="row" style={{ whiteSpace: "nowrap" }} className="fixed-side">N° illimitato</th>
                  {unlimitedComponents}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );

      splitComponents.push(component);
    }
  }
  else {
    splitComponents = [
      <div className="mt-2">Attività chiusa.</div>
    ]
  }

  return <div className="my-1 card card-body py-2">
    <Collapsible transitionTime={200} trigger={
      <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
        <b>
          {weekdayName}
        </b>
        <i className="fas fa-chevron-down ml-2 arrow-collapsible" />
      </div>}>
      {splitComponents}

    </Collapsible>
  </div>
}

export default MaxOrdersScreen;