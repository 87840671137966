import React from 'react';
import { withRouter } from 'react-router-dom';
import { useState } from 'react';
import shortid from 'shortid';
import NumberFormat from 'react-number-format';
import firebase from './../../config/fbConfig'
import { useSelector } from 'react-redux';
import { capitalize } from '../../utility/stringHelper';

function EditPreferenceListScreen(props) {
  const supplierId = useSelector((state) => state.auth.supplierId);
  const list = props.location.state?.list;

  const [name, setName] = useState(list === undefined ? "" : list.name);
  const [displayName, setDisplayName] = useState(list === undefined ? "" : (list.displayName ?? list.name));
  const [hasMax, setHasMax] = useState(list === undefined ? false : list.hasMax);
  const [hasMin, setHasMin] = useState(list === undefined ? false : list.hasMin);
  const [min, setMin] = useState(list === undefined ? 0 : (list.min ?? 0));
  const [max, setMax] = useState(list === undefined ? 0 : (list.max ?? 0));
  const [preferences, setPreferences] = useState(list === undefined ? [] : list.preferences);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  async function onSubmit(e) {
    e.preventDefault();
    if (preferences.length < min && hasMin) {
      setError("Il numero minimo di elementi da selezionare è maggiore degli elementi inseriti.");
      return;
    }
    if (preferences.length === 0) {
      setError("Nessun elemento ancora inserito nella lista.");
      return;
    }
    if (hasMax && hasMin && max < min) {
      setError("Il minimo di elementi selezionabili deve essere inferiore al massimo da selezionare.");
      return;
    }
    try {
      setLoading(true);
      setError("");
      const firestore = firebase.firestore();
      let ref;
      if (list === undefined)
        ref = firestore.collection("suppliers").doc(supplierId).collection("preferenceLists").doc();
      else
        ref = firestore.collection("suppliers").doc(supplierId).collection("preferenceLists").doc(list.id);

      const pref = preferences.slice();

      for (let p of pref) {
        p.name = capitalize(p.name.trim());
      }

      await ref.set({
        id: ref.id,
        name: capitalize(name.trim()),
        displayName: capitalize(displayName.trim()),
        min: hasMin ? min : null,
        max: hasMax ? max : null,
        hasMin,
        hasMax,
        preferences: pref,
      }, { merge: true });

      setLoading(false);
      props.history.push("/preferences/" + ref.id);
    }
    catch (err) {
      console.log(err);
      setError("Errore inaspettato.");
      setLoading(false);
    }
  }

  return <div className="container my-4">
    <form onSubmit={onSubmit}>
      <h4>{list === undefined ? "Creazione lista di preferenze" : "Modifica lista di preferenze"}</h4>
      <h5 className="mt-3">Impostazioni lista</h5>
      <div className="card card-body">
        <div className="form-inline" style={{ maxWidth: "700px" }}>
          <label className="required">Nome</label>
          <input type="text" id="name" style={{ minWidth: "400px" }} className="form-control ml-3" placeholder="Es. Ingredienti aggiuntivi" value={name} onChange={(e) => setName(e.target.value)} required />
        </div>
        <div className="form-inline mt-3" style={{ maxWidth: "700px" }}>
          <label className="required">Titolo da mostrare nell&apos;app</label>
          <input type="text" id="displayName" style={{ minWidth: "400px" }} className="form-control ml-3" placeholder="Es. Ingredienti aggiuntivi" value={displayName} onChange={(e) => setDisplayName(e.target.value)} required />
        </div>
        <div className="custom-control custom-checkbox mt-3">
          <input id="has-min" type="checkbox" className="custom-control-input" checked={hasMin} onChange={(e) => setHasMin(e.target.checked)} />
          <label className="custom-control-label" htmlFor="has-min">Questa lista ha un numero minimo di elementi da selezionare</label>
        </div>
        {
          hasMin === true &&
          <div className="form-inline">
            <label className="required">Quantità minima da selezionare:</label>
            <input type="number" style={{ maxWidth: "200px" }} className="form-control ml-3" value={min} onChange={(e) => setMin(parseInt(e.target.value))} min="1" step="1" required />
          </div>
        }
        <div className="custom-control custom-checkbox mt-3">
          <input id="has-max" type="checkbox" className="custom-control-input" checked={hasMax} onChange={(e) => setHasMax(e.target.checked)} />
          <label className="custom-control-label" htmlFor="has-max">Questa lista ha un numero massimo di elementi selezionabili</label>
        </div>
        {
          hasMax === true &&
          <div className="form-inline">
            <label className="required">Quantità massima selezionabile:</label>
            <input type="number" style={{ maxWidth: "200px" }} className="form-control ml-3" value={max} onChange={(e) => setMax(parseInt(e.target.value))} min="1" step="1" required />
          </div>
        }
      </div>

      <h5 className="mt-4">Elementi della lista</h5>
      {preferences.length === 0 && <div>Nessun elemento ancora aggiunto</div>}
      {preferences.map((p) =>
        <div className="card card-body py-2 mb-2" key={p.id}>
          <div className="d-flex align-items-center">
            <div className="d-flex justify-content-between flex-wrap align-items-center flex-grow-1">
              <div className="form-inline">
                <label className="required">Nome</label>
                <input type="text" style={{ minWidth: "200px" }} className="form-control ml-3" placeholder="Es. Origano" value={p.name}
                  onChange={(e) => {
                    let newPreferences = preferences.slice();
                    let preference = newPreferences.find((p1) => p1.id === p.id);
                    preference.name = e.target.value;
                    setPreferences(newPreferences);
                  }} required />
              </div>
              <div className="form-inline">
                <label className="required">Variazione di prezzo (€)</label>
                <NumberFormat
                  decimalScale={2}
                  allowNegative={true}
                  thousandSeparator={false}
                  decimalSeparator=","
                  prefix="€"
                  fixedDecimalScale={true}
                  className="form-control ml-3"
                  value={p.price}
                  style={{ minWidth: "200px" }}
                  onValueChange={({ floatValue }) => {
                    if (floatValue === p.price)
                      return;
                    let newPreferences = preferences.slice();
                    let preference = newPreferences.find((p1) => p1.id === p.id);
                    preference.price = floatValue;
                    setPreferences(newPreferences);
                  }} required />
              </div>
            </div>
            <div className="ml-3">
              <i className="fas fa-times highlight-on-hover" onClick={() => {
                let newPreferences = preferences.slice();
                let index = newPreferences.findIndex((p1) => p1.id === p.id);
                newPreferences.splice(index, 1);
                setPreferences(newPreferences);
              }} />
            </div>

          </div>
        </div>
      )}
      <div className="mt-2">
        <button className="btn btn-outline-secondary" type="button"
          onClick={() => setPreferences([...preferences, { id: shortid.generate(), name: "", price: 0 }])}>
          Aggiungi elemento
        </button>
      </div>
      <div className="d-flex justify-content-between mt-4">
        <div className="text-danger">{error}</div>
        <button className="btn btn-success" type="submit" disabled={loading}>
          Salva
        </button>
      </div>
    </form>
  </div>
}

export default withRouter(EditPreferenceListScreen);