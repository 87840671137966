import React from 'react';
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase'
import SplashScreen from './../layout/SplashScreen'

function PreferenceListScreen() {
  const supplierId = useSelector((state) => state.auth.supplierId);

  useFirestoreConnect([
    {
      collection: 'suppliers',
      doc: supplierId,
      subcollections: [{
        collection: 'preferenceLists',
      }],
      storeAs: 'preferenceLists',
    },
  ]);

  const preferenceLists = useSelector((state) => state.firestore.ordered.preferenceLists);

  if (!isLoaded(preferenceLists))
    return <SplashScreen />;

  return <div className="container my-4">
    <div className="d-flex align-items-center justify-content-between">
      <h4>Liste di preferenze dei prodotti</h4>
      <Link className="btn btn-outline-primary" to="/preferences/edit">Aggiungi lista</Link>
    </div>

    {preferenceLists.length === 0 && <div>Nessuna lista di preferenze ancora aggiunta.</div>}

    {preferenceLists.map((l) =>
      <div key={l.id} className="my-1" style={{ maxWidth: "800px" }}>
        <Link to={"/preferences/" + l.id} className="card-link">
          <div className="card card-body py-2 mt-2">
            <div className="d-flex justify-content-between align-items-center">
              <div>{l.name}</div>
              <div style={{ color: "grey" }}>{l.preferences.length === 0 ? "Nessun elemento" : (l.preferences.length === 1 ? "1 elemento" : (l.preferences.length.toString() + " preferenze"))}</div>
            </div>
          </div>
        </Link>
      </div>
    )}
  </div>
}

export default PreferenceListScreen;