import React, { Component } from 'react'
import { connect } from 'react-redux'
import { signIn } from '../../store/actions/authActions'
import { Redirect } from 'react-router-dom'

class SignIn extends Component {
  state = {
    email: '',
    password: '',
    passwordVisible: false,
    rememberMe: false,
  }

  handleRememberMeChange = (e) => {
    this.setState({
      rememberMe: e.target.checked,
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.signIn(this.state)
  }

  render() {
    const { authError, auth, location } = this.props;
    if (auth.uid) return <Redirect to={location.state ? location.state.from.pathname : "/"} />
    let eyeStyle = {
      position: 'absolute',
      left: 'auto',
      right: '10px',
      top: '10px',
    }
    return (
      <div className="jumbotron d-flex align-items-center min-vh-100 bg-light">
        <div className="container d-flex justify-content-center">
          <form className="form-signin bg-white p-4">
            <h1 className="h3 mb-3 font-weight-normal">Taporty - Console del fornitore</h1>
            <input type="email" id="email" className="form-control mt-1" placeholder="Indirizzo email" required="" autoFocus="" onChange={this.handleChange} />
            <div style={{ position: "relative " }}>
              <input type={this.state.passwordVisible ? "text" : "password"} id="password" className="form-control mt-1" placeholder="Password" required="" onChange={this.handleChange} />
              <span style={{ cursor: "pointer" }} onClick={() => this.setState({ passwordVisible: !this.state.passwordVisible })}>
                {
                  this.state.passwordVisible === false ?
                    <i className="eye-icon far fa-eye-slash" style={eyeStyle} /> :
                    <i className="eye-icon far fa-eye" style={eyeStyle} />
                }
              </span>
            </div>
            <div className="custom-control custom-checkbox my-2">
              <input type="checkbox" className="custom-control-input" id="rememberMe" checked={this.state.checked} onChange={this.handleRememberMeChange} />
              <label className="custom-control-label" htmlFor="rememberMe">Ricordami</label>
            </div>
            <div className="text-danger mt-1">
              {authError ? <p>{authError}</p> : null}
            </div>
            <button className="btn btn-primary btn-block mt-2" onClick={this.handleSubmit}><i className="fas fa-sign-in-alt"></i>Accedi</button>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
