import React from 'react';
import { sortableHandle, sortableElement, sortableContainer } from 'react-sortable-hoc';

const DragHandle = sortableHandle(() => <i className="fas fa-bars px-4" style={{ cursor: "row-resize", color: "grey" }}></i>);

const ListItem = sortableElement(({ list }) =>
  <div className="card my-1 card-body d-flex py-2 pl-0 pr-4">
    <div className="d-flex">
      <div className="flex-grow-1 d-flex">
        <div className="d-flex my-auto"><DragHandle /></div>
        <div className="d-flex my-auto">
          {list.name}
        </div>
      </div>
    </div>
  </div>
);

const SortableLists = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

function PreferenceListsOrder({ preferenceLists, onSortEnd }) {
  return <div>
    <SortableLists onSortEnd={onSortEnd} useDragHandle lockAxis="y">
      {preferenceLists.map((list, index) => {
        return <ListItem key={list.id} index={index} list={list} />
      })}
    </SortableLists>
  </div>
}

export default PreferenceListsOrder;