import React from "react";
import { useSelector } from "react-redux";
import { isLoaded, useFirestoreConnect } from "react-redux-firebase";
import Supplier from "../../models/supplier";
import { RootState } from "../../store/reducers/rootReducer";
import SplashScreen from "../layout/SplashScreen";
import HolidayEditor from "./components/HolidayEditor";
import WeekdayTimetableEditor from "./components/WeekdayTimetableEditor";

function TimetableScreen() {
  let supplierId = useSelector((state: RootState) => state.auth.supplierId);

  useFirestoreConnect(
    supplierId ? [{
      collection: 'suppliers',
      doc: supplierId,
      storeAs: 'supplier'
    }] : []
  );

  const supplier = useSelector((state: RootState) => state.firestore.data.supplier as Supplier);

  if (!isLoaded(supplier, supplierId))
    return <SplashScreen />;

  return (
    <div className="timetable mx-4">
      <WeekdayTimetableEditor initialWeekdayTimetable={supplier.weekdayTimetable} shiftLength={supplier.shiftLength ?? 15} supplierId={supplierId!} />
      <HolidayEditor holidays={supplier.holidays} supplierId={supplierId!} shiftLength={supplier.shiftLength ?? 15} />
    </div>
  );
}

export default TimetableScreen;