import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changePassword } from '../../store/actions/settingActions'
import { withRouter } from 'react-router-dom';
import { toastr } from "react-redux-toastr";

class Settings extends Component {
  state = {
    oldPassword: "",
    password: "",
    passwordConfirm: "",
  }

  handleFormSubmit = async (e) => {
    e.preventDefault();
    if (this.state.password !== this.state.passwordConfirm) {
      toastr.error("Le password inserite non corrispondono!");
      return;
    }
    this.props.changePassword(this.state);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  render() {
    return (
      <div className="settings container my-2">
        <h4 className="my-3">Cambia password</h4>
        <form onSubmit={this.handleFormSubmit}>
          <div className="card mb-4">
            <div className="mx-4 my-3">
              <div className="form-group">
                <label htmlFor="oldPassword" className="required">Password corrente</label>
                <input className="form-control" minLength="8" type="password" id="oldPassword" value={this.state.oldPassword} required onChange={this.handleChange} />
              </div>
              <div className="form-group">
                <label htmlFor="password" className="required">Password</label>
                <input className="form-control" minLength="8" type="password" id="password" value={this.state.password} required onChange={this.handleChange} />
              </div>
              <div className="form-group">
                <label htmlFor="passwordConfirm" className="required">Conferma password</label>
                <input className="form-control" minLength="8" type="password" id="passwordConfirm" value={this.state.passwordConfirm} required onChange={this.handleChange} />
              </div>
            </div>
          </div>

          <div className="my-2 d-flex justify-content-end">
            <button className="btn btn-success">Salva</button>
          </div>

        </form>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    changePassword: (data) => dispatch(changePassword(data, props.history))
  }
}

export default withRouter(connect(null, mapDispatchToProps)(Settings))