import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'

class DeleteModal extends Component {
  handleCancel = () => {
    this.props.onCancel();
  }

  handleDelete = (e) => {
    e.preventDefault();
    this.props.onDelete();
  }
  render() {
    const { title, message, show } = this.props;
    return (
      <Modal show={show} onHide={this.handleCancel}>
        <form onSubmit={this.handleDelete}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{message}</p>
            <div className="custom-control custom-checkbox">
              <input type="checkbox" className="custom-control-input" id="confirm" required />
              <label className="custom-control-label" htmlFor="confirm">Sono consapevole che questo è irreversibile.</label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" type="submit">
              Elimina
          </Button>
            <Button variant="secondary" type="button" onClick={this.handleCancel}>
              Annulla
          </Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default DeleteModal;
