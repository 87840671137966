import React, { Component } from 'react'
import OrderListHistory from './OrderListHistory';
import moment from 'moment';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

class OrderHistory extends Component {
  constructor() {
    super();
    let today = new Date();
    this.state = {
      startDate: today,
      endDate: today,
    }
  }

  render() {
    return (
      <div className="orderHistory container">
        <div className="d-flex justify-content-between flex-wrap align-items-center mt-3 mb-2">
          <h4 className="my-auto">Storico ordini</h4>
        </div>
        <div className="d-flex flex-wrap mb-3">
          <div className="form-inline mr-4 mt-1">
            <div className="form-group">
              <label htmlFor="startDate">Dal giorno</label>
              <DatePicker selected={this.state.startDate} onChange={date => this.setState({ startDate: date })} dateFormat="dd-MM-yyyy" className="form-control mx-2" />
            </div>
          </div>
          <div className="form-inline mt-1">
            <div className="form-group">
              <label htmlFor="endDate">Al giorno</label>
              <DatePicker selected={this.state.endDate} onChange={date => this.setState({ endDate: date })} dateFormat="dd-MM-yyyy" className="form-control mx-2" />
            </div>
          </div>
        </div>
        <OrderListHistory startDate={moment(this.state.startDate).format("YYYY-MM-DD")} endDate={moment(this.state.endDate).format("YYYY-MM-DD")} />
      </div>
    )
  }
}

export default OrderHistory;